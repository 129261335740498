// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".file-item_root__3EMgX {\n  display: flex;\n  border: 1px solid #ececed;\n  border-radius: 8px;\n  background-color: #ffffff;\n  padding: 10px 12px;\n  width: 100%;\n  max-width: 287px;\n  position: relative;\n}\n.file-item_root__3EMgX.file-item_fullWidth__3porn {\n  width: 100%;\n  max-width: 100%;\n}\n.file-item_root__3EMgX.file-item_fullWidth__3porn .file-item_metaInfo__3Dc0g .file-item_filename__1DzV4 {\n  max-width: 100%;\n}\n.file-item_root__3EMgX.file-item_clickable__1LV8R .file-item_metaInfo__3Dc0g,\n.file-item_root__3EMgX.file-item_clickable__1LV8R .file-item_icon__3DDT6 {\n  cursor: pointer;\n}\n.file-item_root__3EMgX .file-item_icon__3DDT6 {\n  height: 28px;\n  width: 22px;\n  margin-right: 10px;\n}\n.file-item_root__3EMgX .file-item_metaInfo__3Dc0g {\n  display: flex;\n  flex-direction: column;\n}\n.file-item_root__3EMgX .file-item_metaInfo__3Dc0g .file-item_filename__1DzV4 {\n  color: #121215;\n  font-size: 13px;\n  font-weight: 500;\n  letter-spacing: -0.2px;\n  line-height: 15px;\n  margin-bottom: 1px;\n  word-break: break-all;\n  padding-right: 20px;\n}\n.file-item_root__3EMgX .file-item_metaInfo__3Dc0g .file-item_size__18EJC {\n  color: #727275;\n  font-size: 12px;\n  letter-spacing: -0.22px;\n  line-height: 14px;\n}\n.file-item_root__3EMgX .file-item_deleteBtn__ItCL8 {\n  position: absolute;\n  right: 10px;\n  top: 50%;\n  transform: translateY(-50%);\n  color: #727275;\n}", ""]);
// Exports
exports.locals = {
	"root": "file-item_root__3EMgX",
	"fullWidth": "file-item_fullWidth__3porn",
	"metaInfo": "file-item_metaInfo__3Dc0g",
	"filename": "file-item_filename__1DzV4",
	"clickable": "file-item_clickable__1LV8R",
	"icon": "file-item_icon__3DDT6",
	"size": "file-item_size__18EJC",
	"deleteBtn": "file-item_deleteBtn__ItCL8"
};
module.exports = exports;
