import { useEffect } from 'react';

import {
  getActiveTabFromString,
  getCategoryFromString,
  getDateRangeFromString,
} from '@puretax/shared';
import { useSnackbar } from '@puretax/ui';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export type TodoViewRedirectProps = RouteComponentProps<MatchParams>;

interface MatchParams {
  clientId: string;
  id: string;
}
const TodoViewRedirect = (props: TodoViewRedirectProps) => {
  const clientId = +props.match.params.clientId;
  const id = props.match.params.id;
  console.log(props.match.params, 'props.match.params');
  useEffect(() => {
    if (props.match.params) {
      props.history.push({
        pathname: `/login`,
        state: {
          data: {
            id,
          },
          clientId,
          isFinancial: false,
        },
      });
    }
  }, [clientId, id, props.history, props.match.params]);
  return null;
};

export default withRouter(TodoViewRedirect);
