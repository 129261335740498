import React, { useEffect, useState } from 'react';

import { useMediaQuery } from '@material-ui/core';
import {
  access_level,
  ClientMethod,
  getDefaultClient,
  removeAuthenticationDetails,
  setDefaultClient,
  useCurrentUser,
  useProfileType,
} from '@puretax/data-access';
import {
  AccountantIcon,
  AccountingIcon,
  AdminIcon,
  Client,
  ClientIcon,
  CloseIcon,
  CollapseIcon,
  ExpandIcon,
  getClient,
  getErrorMessages,
  getFullName,
  HelpIcon,
  insertAt,
  LogoutIcon,
  NotificationIcon,
  PersonalSettingsIcon,
  RecordIcon,
  SettingsIcon,
  TaxIcon,
  TodoIcon,
  UploadIcon,
  WorkIcon,
} from '@puretax/shared';
import {
  AppLogo,
  Avatar,
  AvatarSize,
  Button,
  MetaInfo,
  SlideSwitch,
  Divider,
  UserProfileDropdown,
  UserInfoCard,
  useSnackbar,
} from '@puretax/ui';
import clsx from 'clsx';
import { NavLink, useHistory } from 'react-router-dom';

import { ChooseClientPopover } from '../choose-client-popover/choose-client-popover';
import styles from './sidebar.module.scss';

interface SidebarProps {
  className?: string;
  isMobile?: boolean;
  onClose?: () => void;
  collapseSidebar?: boolean;
}

export const Sidebar = ({
  className,
  isMobile,
  onClose,
  collapseSidebar,
  ...props
}: SidebarProps) => {
  const breakpoint_xl = useMediaQuery('(min-width: 1200px)', { noSsr: true });
  const { showSnackbar } = useSnackbar();
  const [collapse, setCollapse] = useState(false);
  const { setView, profileType } = useProfileType();
  const { currentUser } = useCurrentUser();
  const { profile } = currentUser;
  const { isClient } = access_level.get();
  const fullName = getFullName(profile?.first_name, profile?.last_name);
  const [clients, setClients] = useState<Client[]>();
  const [slideSwitchRendered, setSlideSwitchRendered] = useState(false);

  const history = useHistory();
  useEffect(() => {
    if (isMobile) return;
    const spaceEl = document.getElementById('sidebar-space');
    if (spaceEl) {
      if (collapse) {
        spaceEl.style['margin-left'] = '60px';
      } else {
        spaceEl.style['margin-left'] = '256px';
      }
    }
  }, [collapse, isMobile, window.location.pathname]);

  useEffect(() => {
    const spaceEl = document.getElementById('sidebar-space');
    if (window.location.pathname === '/financials') {
      if (isMobile) return;
      if (collapseSidebar) {
        spaceEl.style['margin-left'] = '30px';
        spaceEl.style['margin-right'] = '250px';
      } else if (!collapse) {
        spaceEl.style['margin-left'] = '256px';
        spaceEl.style['margin-right'] = '0px';
      } else {
        spaceEl.style['margin-left'] = '60px';
        spaceEl.style['margin-right'] = '0px';
      }
    } else {
      spaceEl.style['margin-right'] = '0px';
    }
  }, [collapseSidebar, window.location.pathname]);

  useEffect(() => {
    if (collapseSidebar && window.location.pathname === '/financials')
      setCollapse(true);
    else setCollapse(false);
  }, [collapseSidebar]);

  const onSlide = async (view: string) => {
    try {
      const res = await ClientMethod.fetchClientBasicList({
        accountant: profile.id,
      });
      setClients(res.data);
      if (view === 'admin') {
        setView(view);
        history.push('/admin');
      } else if (view === 'accountant') {
        if (res.data?.length === 1) {
          setDefaultClient(res.data[0]?.id);
        }
        const defaultClient = res.data?.find(
          (i) => i.id === getDefaultClient()
        );

        if (!defaultClient && currentUser.clients)
          history.push('/switch-client');
        else {
          sessionStorage.setItem('profileType', '"accountant"');
          setView(view);
        }
      }
    } catch (error) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const getMenuCount = (label: string) => {
    if (label === 'To-do' && currentUser.open_tasks > 0)
      return (
        <div className={clsx(styles.taskCountWrap, styles.activeLinkCount)}>
          {currentUser.open_tasks}
        </div>
      );
    else if (label === 'Tax' && currentUser.open_tax_tasks > 0)
      return (
        <div className={clsx(styles.taskCountWrap, styles.activeLinkCount)}>
          {currentUser.open_tax_tasks}
        </div>
      );
    else return '';
  };

  const getMenuList = (): {
    label: string;
    icon: any;
    to: string;
    have_space?: boolean;
    have_divider?: boolean;
  }[] => {
    const adminList = [
      {
        label: 'Clients',
        icon: ClientIcon,
        to: '/clients',
      },
      {
        label: 'Accountants',
        icon: AccountantIcon,
        to: '/accountants',
      },
    ];
    const accountantList = [
      {
        label: 'To-do',
        icon: TodoIcon,
        to: '/to-do',
      },
      {
        label: 'Uploads',
        icon: UploadIcon,
        to: '/uploads',
      },
      {
        label: 'Financials',
        icon: AccountingIcon,
        to: '/financials',
      },
      {
        label: 'Tax',
        icon: TaxIcon,
        to: '/tax',
      },

      {
        label: 'Records',
        icon: RecordIcon,
        to: '/records',
      },
    ];
    const commonList = [
      {
        label: 'Account Settings',
        icon: PersonalSettingsIcon,
        to: '/personal-settings',
        have_divider: true,
      },
      // {
      //   label: 'Help',
      //   icon: HelpIcon,
      //   to: '/help',
      // },
      // {
      //   label: 'Notification',
      //   icon: NotificationIcon,
      //   to: '/notification',
      // },
    ];
    const settings = {
      label: 'Settings',
      icon: SettingsIcon,
      to: '/settings',
      have_space: true,
    };
    if (access_level.get().isAccountant) {
      insertAt(accountantList, 1, {
        label: 'Work',
        icon: WorkIcon,
        to: '/works',
      });

      accountantList.push(settings);
    }

    if (profileType === 'admin') {
      const adminFullList = adminList.concat(commonList);
      if (breakpoint_xl) {
        return adminList;
      } else {
        return adminFullList;
      }
    } else {
      const accountantFullList = accountantList.concat(commonList);
      if (breakpoint_xl) {
        return accountantList;
      } else {
        return accountantFullList;
      }
    }
  };
  const getUserInfoSection = () => (
    <UserInfoCard
      title={fullName}
      className={styles.userCard}
      desc={''}
      pic={profile?.photo?.file_url}
      avatarSize={AvatarSize.avatar28}
    />
  );
  const getLogoutButton = () => {
    return (
      <div
        className={styles.logoutBtn}
        onClick={async () => {
          await removeAuthenticationDetails();
          history.push('/login');
        }}
      >
        <LogoutIcon />
        Log out
      </div>
    );
  };
  const client = getClient(
    slideSwitchRendered ? clients : currentUser.clients,
    getDefaultClient()
  );

  return (
    <nav
      className={clsx(
        styles.root,
        className,
        isMobile && styles.is_mobile,
        collapse && styles.collapse
      )}
    >
      <div className={styles.actionBtn}>
        {!isMobile ? (
          <Button
            onClick={() => setCollapse(true)}
            size="s"
            variant="text"
            color="secondary"
            className={styles.collapseBtn}
            ghost
          >
            <CollapseIcon />
          </Button>
        ) : null}
      </div>

      <div className={styles.rootInner}>
        <div className={styles.app_logo_wrap}>
          <div className={!isMobile ? styles.logo : null}>
            <AppLogo />
            {!isMobile ? (
              collapse ? (
                <div className={styles.expandBtnWrap}>
                  <Button
                    onClick={() => setCollapse(false)}
                    size="s"
                    variant="text"
                    color="secondary"
                    className={styles.expandBtn}
                    ghost
                  >
                    <ExpandIcon />
                  </Button>
                </div>
              ) : null
            ) : null}
          </div>

          {isMobile && (
            <Button
              onClick={onClose}
              size="xs"
              variant="text"
              className={styles.closeIcon}
              ghost
            >
              <CloseIcon />
            </Button>
          )}
        </div>

        <ul className={styles.menu_list}>
          {getMenuList().map((item, index) => {
            const Icon = item.icon;
            return (
              <li
                key={index}
                className={clsx(
                  styles.menu_item,
                  item?.have_space && styles.have_space,
                  item?.have_divider && styles.have_divider
                )}
              >
                <NavLink
                  to={item.to}
                  onClick={onClose}
                  activeClassName={styles.active}
                  className={styles.link_item}
                >
                  <div className={styles.iconWrap}>
                    <Icon />
                  </div>
                  <div
                    className={clsx(
                      styles.link_item_label,
                      styles.labelWithCount
                    )}
                  >
                    {item.label}
                    {isClient ? getMenuCount(item.label) : null}
                  </div>
                </NavLink>
              </li>
            );
          })}
        </ul>

        {access_level.get().isAdmin ? (
          <div className={styles.mobBottom}>
            <div className={styles.adminSwitchCompactView}>
              {profileType === 'admin' ? <AdminIcon /> : <AccountantIcon />}
            </div>

            {profileType === 'accountant' ? (
              <div className={styles.clientChooseBtn}>
                <Avatar
                  name={client?.name}
                  url={client?.logo?.file_url}
                  size={AvatarSize.avatar28}
                  className={styles.avatar}
                />
              </div>
            ) : null}
          </div>
        ) : null}

        <div className={styles.bottom}>
          {!breakpoint_xl ? (
            <div className={styles.dropdownMetaInfoWrap}>
              {getUserInfoSection()}
              {getLogoutButton()}
            </div>
          ) : null}
          <div>
            {access_level.get().isAdmin ? (
              <SlideSwitch
                className={styles.adminSwitch}
                showLargeSlide
                selected={profileType}
                onChange={(view) => {
                  setSlideSwitchRendered(true);
                  onSlide(view);
                }}
                options={[
                  {
                    label: 'Admin',
                    value: 'admin',
                    icon: <AdminIcon />,
                  },
                  {
                    label: 'Accountant',
                    value: 'accountant',
                    icon: <AccountantIcon />,
                  },
                ]}
              />
            ) : null}
            {profileType === 'accountant' && access_level.get().isAccountant ? (
              <div className={styles.clientChooseBtn}>
                <ChooseClientPopover client={client} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Sidebar;
