import React, { ReactElement } from 'react';

import { getColorFromString, getInitialsFromString } from '@puretax/shared';
import clsx from 'clsx';

import styles from './avatar.module.scss';

export enum AvatarSize {
  avatar18 = 'avatar18',
  avatar20 = 'avatar20',
  avatar22 = 'avatar22',
  avatar24 = 'avatar24',
  avatar26 = 'avatar26',
  avatar28 = 'avatar28',
  avatar30 = 'avatar30',
  avatar32 = 'avatar32',
  avatar34 = 'avatar34',
  avatar36 = 'avatar36',
  avatar38 = 'avatar38',
  avatar40 = 'avatar40',
  avatar48 = 'avatar48',
  avatar50 = 'avatar50',
  avatar52 = 'avatar52',
  avatar60 = 'avatar60',
  avatar64 = 'avatar64',
  avatar68 = 'avatar68',
  avatar72 = 'avatar72',
  avatar74 = 'avatar74',
  avatar86 = 'avatar86',
  avatar96 = 'avatar96',
  avatar100 = 'avatar100',
  avatar120 = 'avatar120',
}
export interface AvatarProps extends React.HTMLAttributes<HTMLElement> {
  size?: AvatarSize;
  url?: string;
  icon?: ReactElement;
  name?: string;
  className?: string;
  circle?: boolean;
  color?: string;
  style?: React.CSSProperties;
}

export const Avatar: React.FC<AvatarProps> = ({
  size = AvatarSize.avatar22,
  url,
  icon,
  name,
  className,
  circle,
  color,
  style,
}: AvatarProps) => {
  const defaultColor = name && getColorFromString(name);
  const avatarInitial = getInitialsFromString(name);
  const avatarClassName = clsx(styles.avatar, styles[size], className, {
    [styles.circle]: circle,
  });
  return (
    <div
      className={avatarClassName}
      style={{
        ...style,
        backgroundColor: url ? '#fff' : color || defaultColor,
      }}
    >
      {url ? (
        <img src={url} alt={name} />
      ) : icon ? (
        icon
      ) : (
        <span>{avatarInitial}</span>
      )}
    </div>
  );
};
