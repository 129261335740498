import { useEffect, useMemo, useRef, useState } from 'react';

import {
  CommentBoxMethod,
  access_level,
  useCurrentUser,
} from '@puretax/data-access';
import {
  CloseIcon,
  CommentIcon,
  CommentItemType,
  CommentLargeIcon,
  DeleteIcon,
  MultiMessageIcon,
  ResolveIcon,
  SendMessageIcon,
  User,
  getErrorMessages,
  getFullName,
  userMsgs,
} from '@puretax/shared';
import { RichEditor, Tooltip, Typography, useSnackbar } from '@puretax/ui';
import clsx from 'clsx';
import { debounce } from 'lodash';

import { Avatar, AvatarSize } from '../avatar/avatar';
import { Divider } from '../divider/divider';
import MuiPopperComponent from '../mui-popper/mui-popper';
import styles from './comment-box.module.scss';

interface CommentBoxProps {
  isActive: boolean;
  obejctId: string;
  commentsList: CommentItemType[];
  onButtonClick: (childKeys: string[]) => void;
  onCommentsClose: () => void;
  onDeleteThread: () => void;
  onCommentsUpdate: (comment: CommentItemType, isDelete?: boolean) => void;
  onEditComment: (comment: CommentItemType) => void;
  className?: string;
  buttonClassName?: string;
  onPopperOpen?: () => void;
  mentionUsers?: User[];
  headerId?: string[];
  anchorOrigin?: 'left' | 'right' | 'topLeft' | 'center';
  paperClassName?: string;
  isDetailedView?: boolean;
}
interface _fileType {
  files?: File[];
}
interface Formvalues {
  comment?: string;
  files?: File[];
}

export const CommentBox = ({
  isActive,
  onButtonClick,
  onCommentsClose,
  buttonClassName,
  headerId,
  onDeleteThread,
  onCommentsUpdate,
  isDetailedView,
  onEditComment,
  ...props
}: CommentBoxProps) => {
  const btnRef = useRef();
  const { currentUser } = useCurrentUser();
  const { profile } = currentUser;
  const { isAdmin } = access_level.get();
  const [values, setValues] = useState<string>();
  const [commentsList, setCommentsList] = useState<CommentItemType[]>();
  const [childKeys, setChildKeys] = useState<string[]>();
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const [resolvedStatus, setResolvedStatus] = useState<'open' | 'closed'>();
  const [isPopperOpen, setIsPopperOpen] = useState(isActive);
  const [editableComments, setEditableComments] = useState<Array<number>>([]);

  useEffect(() => {
    setIsPopperOpen(isActive);
  }, [isActive]);
  useEffect(() => {
    if (commentsList) {
      setResolvedStatus(
        commentsList?.[commentsList?.length - 1]?.status
          ? commentsList?.[commentsList?.length - 1]?.status
          : 'open'
      );
    }
  }, [commentsList]);

  const getCommentListItem = () => {
    const singlethreadComments = props.commentsList?.filter(
      (item) => item.key === props.obejctId
    );
    const keyParts = props.obejctId.split('-');
    const parentID = keyParts.slice(7)[0];
    const cellIndex = keyParts.slice(9)[0];
    const tabId = keyParts.slice(6)[0];
    const res = props.commentsList?.filter((item) => {
      const objKeyParts = item.key.split('-');
      const objParentIDs = objKeyParts.slice(9 + 1);
      const objId = objKeyParts.slice(7)[0];
      const objCellIndex = objKeyParts.slice(9)[0];
      const objTabId = objKeyParts.slice(6)[0];
      return (
        objParentIDs.includes(parentID) &&
        cellIndex === objCellIndex &&
        objTabId === tabId &&
        !objParentIDs.includes(objId)
      );
    });

    const uniqueKeys = [...new Set(res.map((obj: CommentItemType) => obj.key))];
    setChildKeys(uniqueKeys as string[]);

    setCommentsList(singlethreadComments);
  };

  useEffect(() => {
    getCommentListItem();
  }, [props.commentsList]);

  const closePopper = () => {
    onCommentsClose();
    setIsPopperOpen(false);
  };

  const onEditInput = useMemo(
    () =>
      debounce((description: string, commentId: number) => {
        editComment(description, commentId);
      }, 100),
    [values]
  );

  const onCommentSubmit = async (comment: string) => {
    const payload = { description: comment, key: props.obejctId };
    try {
      const res = await CommentBoxMethod.postComment(payload);
      onCommentsUpdate(res.data);
      setValues('');
    } catch (error) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };
  const editComment = async (description: string, commentId: number) => {
    const payload = {
      description: description,
      // key: props.obejctId,
      id: commentId,
    };

    try {
      const res = await CommentBoxMethod.editComment(payload);
      onEditComment(res.data);
      showSnackbar(true, {
        msg: userMsgs.comment.update,
        severity: 'success',
      });
    } catch (error) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const onCommentToggle = async (status: 'open' | 'closed') => {
    const payload = { status: status, key: props.obejctId };
    try {
      const res = await CommentBoxMethod.toggleComment(payload);
      // setCommentsList(res.data);
      setResolvedStatus(status);
    } catch (error) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const fullName = getFullName(profile?.first_name, profile?.last_name);

  const deleteComment = async (commentId: number) => {
    try {
      await CommentBoxMethod.deleteComment(commentId);
      onCommentsUpdate({ id: commentId } as CommentItemType, true);
    } catch (error) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const getTitleMarkup = () => {
    return (
      <div className={styles.titleWrap}>
        <Typography
          variant="body2"
          fontWeight="semiBold"
          className={styles.title}
        >
          COMMENTS
        </Typography>
        {commentsList?.length ? (
          <Tooltip
            variant="dark"
            title={resolvedStatus !== 'open' ? 'Reopen' : 'Resolve'}
            className={styles.resolveIcon}
          >
            <div
              className={styles.titleIconsWrap}
              onClick={() =>
                onCommentToggle(resolvedStatus !== 'open' ? 'open' : 'closed')
              }
            >
              <ResolveIcon
                className={clsx(resolvedStatus !== 'open' && styles.resloved)}
              />
            </div>
          </Tooltip>
        ) : null}
        {commentsList?.length ? (
          <div
            className={styles.titleIconsWrap}
            onClick={() => {
              onDeleteThread();
            }}
          >
            <DeleteIcon />
          </div>
        ) : null}
        <div className={styles.titleIconsWrap} onClick={() => closePopper()}>
          <CloseIcon className={styles.closeIcon} />
        </div>
      </div>
    );
  };

  const getHeaderSection = () => {
    return !commentsList?.length ? (
      <div className={styles.headerContentWrap}>
        <CommentLargeIcon className={styles.messageIcon} />
        <div className={styles.headerText}>Add a comment</div>
        <div className={styles.contentText}>
          Try using Puretax’s commenting feature. Comments can be seen by all
          client users.
        </div>
      </div>
    ) : (
      getCommentsList()
    );
  };

  // const toggleEditable = (commentId: number) => {
  //   setEditableComments((prevEditableComments) => {
  //     if (prevEditableComments.includes(commentId)) {
  //       return prevEditableComments.filter((id) => id !== commentId);
  //     } else {
  //       return [...prevEditableComments, commentId];
  //     }
  //   });
  // };

  const getCommentsList = () => {
    return (
      <div className={clsx(commentsList?.length && styles.commentsDividerWrap)}>
        <div className={styles.commentsListWrap}>
          {commentsList?.map((item, index) => {
            return (
              <div className={styles.commentsItemInfoWrap} key={item.id}>
                <div className={styles.avatarNameWrap}>
                  <div className={styles.avatarNameInner}>
                    <Avatar
                      name={item.created_by?.name}
                      circle
                      size={AvatarSize.avatar20}
                      url={profile?.photo?.file_url}
                    />

                    <div className={styles.userName}>
                      {item.created_by?.name}
                    </div>
                  </div>
                  {isAdmin || item.created_by.id === profile.id ? (
                    <div className={styles.titleIconsWrap}>
                      {/* <button
                        className={clsx(
                          styles.deleteIconWrap,
                          resolvedStatus === 'closed' && styles.disabled
                        )}
                        onClick={() => toggleEditable(item.id)}
                        disabled={resolvedStatus === 'closed'}
                      >
                        <EditIcon />
                      </button> */}

                      <button
                        className={clsx(
                          styles.deleteIconWrap,
                          resolvedStatus === 'closed' && styles.disabled
                        )}
                        onClick={() => deleteComment(item.id)}
                        disabled={resolvedStatus === 'closed'}
                      >
                        <DeleteIcon />
                      </button>
                    </div>
                  ) : null}
                </div>
                <div className={styles.commentsListItem}>
                  <RichEditor
                    key={item.id}
                    onChange={(value) => {
                      // onEditInput(value, item.id);
                    }}
                    className={styles.desc}
                    placeholder={'Add comments…'}
                    value={item.description}
                    isReadOnly={
                      !(isAdmin || item.created_by.id === profile.id) ||
                      resolvedStatus === 'closed'
                    }
                    mentionList={props.mentionUsers}
                    showMentions
                    onBlur={(value) => {
                      onEditInput(value, item.id);
                    }}
                    // onFocus={}
                  />
                </div>
                {/* {item?.files?.length ? (
                    <div className={styles.fileItems}>
                      {item?.files?.map((file, index) => {
                        return (
                          <FileItem
                            disabled={isSubmitting}
                            key={index}
                            file={file}
                            onRemove={() => {}}
                          />
                        );
                      })}
                    </div>
                  ) : null} */}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const handleClick = () => {
    onCommentSubmit(values);
  };

  const getContentMarkup = () =>
    resolvedStatus === 'open' && getCommentAddSection();

  const getCommentAddSection = () => {
    return (
      <div className={styles.inputWrap}>
        <div className={styles.richEditorWrap}>
          <div className={styles.richEditor}>
            <RichEditor
              onChange={(value) => {
                setValues(value);
              }}
              className={styles.desc}
              placeholder={'Add a comment. Use @ to mention.'}
              value={values}
              onFocus={() => setInputFocused(true)}
              mentionList={props.mentionUsers}
              showMentions
              // onBlur={() => onBlurComment(comment)}
              // isUploadImage={(value) => {
              //   setIsImageUploading(value);
              //   onImageUpload?.(value);
              // }}
            />
          </div>
          <SendMessageIcon
            onClick={() => handleClick()}
            className={clsx(
              styles.sendCommentIcon,
              values?.length > 0 && styles.active
            )}
          />
        </div>

        {/* {percentage ? <ProgressUpload percentage={percentage} /> : null} */}
        {/* {values?.files?.length ? (
          <div className={styles.fileItems}>
            {values?.files?.map((file, index) => {
              return (
                <FileItem
                  disabled={isSubmitting}
                  key={index}
                  file={file}
                  showDelete
                  onRemove={() => {
                    removeFile(index);
                  }}
                />
              );
            })}
          </div>
        ) : null} */}

        {/* {inputFocused ? (
          <div className={styles.extraContentWrap}>
            <button
              className={styles.commentInputIcons}
              onClick={() => addMentionLetter('@')}
            >
              <MentionIcon />
            </button>
            <button
              className={styles.commentInputIcons}
              onClick={() => togglePicker()}
            >
              <EmojiIcon />
            </button>
            <button className={styles.commentInputIcons}>
              <TaskIcon />
            </button>
            <button className={styles.commentInputIcons}>
              <FileUploader
                buttonProps={{ children: <FileUploadIcon />, variant: 'text' }}
                multiple
                onSelectMultiple={(_files: any) => {
                  setValues({
                    ...values,
                    files: [...(values?.files || []), ..._files],
                  });
                }}
              />
            </button>
          </div>
        ) : null} */}
      </div>
    );
  };

  const keyParts = props.obejctId.split('-');
  const objID = keyParts.slice(7)[0];
  const filteredChildKeys: any =
    headerId?.length > 0
      ? headerId.some((id) => objID === id)
        ? []
        : childKeys
      : childKeys;
  const renderButton = ({ active, ...props }) => {
    return (
      <button
        ref={btnRef}
        className={clsx(styles.userProfileBtn, buttonClassName, 'btn')}
        {...props}
        onClick={(e) => {
          if (filteredChildKeys?.length === 0 || isDetailedView) {
            props?.onClick(e);
          } else {
            e.stopPropagation();
          }
          onButtonClick(filteredChildKeys);
        }}
        id={props.id + 'btn'}
      >
        <div className={styles.avatarWrap}>
          <Avatar
            name={fullName}
            circle
            size={AvatarSize.avatar22}
            url={profile?.photo?.file_url}
            className={styles.avatar}
          />
        </div>
        {filteredChildKeys?.length === 0 || isDetailedView ? (
          <CommentIcon
            className={clsx(
              styles.messageIcon,
              isPopperOpen && styles.messageIconActive,
              resolvedStatus !== 'open' && styles.resloved
            )}
          />
        ) : (
          <MultiMessageIcon
            className={clsx(
              styles.messageIcon,
              isPopperOpen && styles.messageIconActive,
              resolvedStatus !== 'open' && styles.resloved
            )}
          />
        )}
      </button>
    );
  };

  return (
    <div
      className={clsx(
        styles.root,
        props.className,
        (commentsList?.[0]?.description?.length > 0 ||
          (isDetailedView ? false : filteredChildKeys?.length > 0)) &&
          styles.active
      )}
      onClick={(e) => e.stopPropagation()}
    >
      <MuiPopperComponent
        id={props.obejctId}
        open={isPopperOpen}
        onOpen={() => setIsPopperOpen(true)}
        onClose={() => onCommentsClose()}
        renderButton={renderButton}
        anchorOrigin={props.anchorOrigin ?? 'right'}
        paperClassName={clsx(props.paperClassName, styles.paperClassName)}
      >
        <div className={styles.container}>
          {getTitleMarkup()}
          {getHeaderSection()}
          <Divider />
          {getContentMarkup()}
        </div>
      </MuiPopperComponent>
    </div>
  );
};
