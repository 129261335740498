import React from 'react';

import { useProfileType } from '@puretax/data-access';
import { AppLogoIcon } from '@puretax/shared';
import { NavLink } from 'react-router-dom';

import styles from './app-logo.module.scss';

/* eslint-disable-next-line */
interface AppLogoProps {
  className?: string;
}

export const AppLogo = ({ className, ...restProps }: AppLogoProps) => {
  const { profileType } = useProfileType();
  return (
    <NavLink
      className={className}
      to={profileType === 'admin' ? '/clients' : '/to-do'}
    >
      <div className={styles.app_logo}>
        <AppLogoIcon />
      </div>
    </NavLink>
  );
};
export default AppLogo;
