import moment from 'moment';
import { stringify } from 'query-string';
import { useEffect, useRef } from 'react';
import { projectColors } from '../constant';
import { MonthsEntityOrQuartersEntity, WorkStatus } from '../types';
import { TextField, withStyles } from '@material-ui/core';
import {
  getDefaultClient,
  getAccessToken,
} from '../../../data-access/src/local-storage';

export const useEffectOnce = (cb) => {
  const didRun = useRef(false);
  useEffect(() => {
    if (!didRun.current) {
      cb();
    }
    didRun.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const getHashForString = (str: string): number => {
  let hash = 0;
  if (!str) return hash;
  str.split('').forEach((_letter, index) => {
    const charCode = str.charCodeAt(index);
    hash += charCode;
  });

  return hash;
};

export const getColorFromString = (name: string): string => {
  let random = getHashForString(name);
  random = random % projectColors.length;
  return projectColors[random];
};

export const getInitialsFromString = (
  name = '',
  firstLetter = true
): string => {
  if (!name) return '';
  if (firstLetter) {
    return name[0];
  }
  const names = name.split(' ');
  if (names.length > 1) {
    const initial =
      names[0].substring(0, 1) + names[names.length - 1].substring(0, 1);
    return initial;
  } else {
    return names[0].substring(0, 1);
  }
};

export const toUpperCase = (str = '') => str.toUpperCase();
export const toLowerCase = (str = '') => str.toLowerCase();
export const capitalize = (str = '') => {
  str = toLowerCase(str);
  return str.charAt(0).toUpperCase() + str.slice(1);
};

//Validation
export const validateEmail = (email: string) => {
  return email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
};

export const handlePropagation = (e) => {
  e.stopPropagation();
};

export const clone = <T>(data: T): T => {
  return JSON.parse(JSON.stringify(data));
};

export const isEmpty = <T>(dataObject: T): boolean => {
  return (
    dataObject &&
    Object.keys(dataObject).length === 0 &&
    dataObject.constructor === Object
  );
};

export const isObject = <T>(object: T) => {
  return object != null && typeof object === 'object';
};

export const isDeepEqual = <T, K>(object1: T, object2: K) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) return false;
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !isDeepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

export const getFullName = (firstName: string, lastName: string) => {
  return firstName + ' ' + lastName;
};

export const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const numberWithCommas = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getSessionStorageValue = (key, defaultValue) => {
  // getting stored value
  const saved = sessionStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
};

export const validateForm = {
  isEmail: (email: string | undefined) => {
    return !email ? false : !validateEmail(email);
  },
  isRequired: (value: string) => {
    return value ? value !== '' : false;
  },
  isNumber: (value) => {
    return value !== '' && !isNaN(value);
  },
  isPhoneNumber: (value: string) => {
    return (
      value !== '' &&
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(value)
    );
  },
  isZipCode: (value: string) => {
    return value !== '' && /^[0-9]{0,7}$/im.test(value);
  },
  isEinNumber: (value: string) => {
    return value !== '' && /^\d{2}(-\d{7})?$/im.test(value);
  },

  validateUrl(url: string) {
    return (
      url &&
      !new RegExp(
        '^((smt|ft|htt)ps?:\\/\\/)?' +
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
          '((\\d{1,3}\\.){3}\\d{1,3}))' +
          '(\\:\\d+)?' +
          '(\\/[-a-z\\d%@_.~+&:]*)*' +
          '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' +
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ).test(url)
    );
  },

  isValidName: (value: string) => {
    const format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    return value !== '' && !format.test(value);
  },
  isValidPassword: (value: string) => {
    return (
      value !== '' &&
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/im.test(
        value
      )
    );
  },
  isLandLineNumber: (value: string) => {
    return (
      value !== '' &&
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/im.test(value)
    );
  },
};

export const removeAppLoading = (): void => {
  // Position fixed doesn't work inside transform or will-change.
  // So, adding transform via s separate class which will be removed once the transition is completed.
  const body = document.body;
  body && body.classList.add('puretax-app-loaded');
  const root = document.getElementById('root');
  root && root.classList.add('puretaxAppTransformIn');
  setTimeout(() => {
    const loading = document.getElementById('puretaxAppLoading');
    loading && loading.parentNode && loading.parentNode.removeChild(loading);
    root &&
      root.classList.remove('puretaxAppTransform', 'puretaxAppTransformIn');
  }, 600);
};

export const sortNullishValues = (arr = [], key: string) => {
  const assignValue = (val) => {
    if (val === null) {
      return Infinity;
    } else {
      return val;
    }
  };
  const sorter = (a, b) => assignValue(a[key]) - assignValue(b[key]);
  return arr.sort(sorter);
};

export const getPaginationCounts = (
  page: number,
  pageSize: number,
  total: number
) => {
  const c = (page - 1) * pageSize;
  const a = total - c;
  const first = c + 1;
  let second;
  if (a > pageSize) {
    second = c + pageSize;
  } else {
    second = c + a;
  }
  return { first, second };
};

export const getQueryString = <T>(filter: T) => {
  const queryParams = stringify({ ...filter }, { skipNull: true });
  return queryParams ? '?' + queryParams : '';
};

export const insertAt = <T>(array: T[], index, elementsArray: T): T[] => {
  array.splice(index, 0, elementsArray);
  return array;
};

export const removeAt = <T>(arr: Array<T>, index: number): Array<T> => {
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const getDateInIntervalFormat = (diff: number) => {
  let label = '';
  if (diff < 0) {
    diff = Math.abs(diff);
    label = diff === 1 ? `${diff} day late` : `${diff} days late`;
  } else if (diff === 0) {
    label = 'Today';
  } else {
    label = diff === 1 ? `In ${diff} day` : `In ${diff} days`;
  }
  return label;
};

export const getYearsList = () => {
  const max = new Date().getFullYear();
  const min = 2020;
  const years = [];

  for (let i = min; i <= max; i++) {
    years.push({ value: i + '', label: i });
  }
  return years;
};
export const getMonthsFromYear = () => {
  const monthsList = [
    { value: '1', label: 'Jan' },
    { value: '2', label: 'Feb' },
    { value: '3', label: 'Mar' },
    { value: '4', label: 'Apr' },
    { value: '5', label: 'May' },
    { value: '6', label: 'Jun' },
    { value: '7', label: 'Jul' },
    { value: '8', label: 'Aug' },
    { value: '9', label: 'Sep' },
    { value: '10', label: 'Oct' },
    { value: '11', label: 'Nov' },
    { value: '12', label: 'Dec' },
  ];
  return monthsList;
};

export const getDaysInMonth = (month) => {
  const date = new Date(new Date().getFullYear(), month, 1);
  const days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  // eslint-disable-next-line no-lone-blocks
  return days.map((day) => {
    const dayLabel = moment(day).format('DD');
    return { value: dayLabel + '', label: dayLabel };
  });
};

export const sumOfArray = (array: number[]) => {
  const sum = array.reduce((partial_sum, a) => partial_sum + a, 0);
  return sum;
};

export const onUploadProgress = (total, loaded) => {
  const percentCompleted = Math.round((loaded * 100) / total);
  return percentCompleted;
};

export const getDefaultSelectItemByValue = (array, value: string | number) => {
  const _item = array.find((item) => +item.value === value);
  return _item;
};
export const getDefaultSelectItemByLabel = (array, value: string | number) => {
  const _item = array.find((item) => item.value === value);
  return _item?.label;
};

export const getDefaultSelectItem = (array, value: string | number) =>
  array.find((item) => item.value === value);

export const downloadFile = (url: string, fileName?: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export const getAmountLabel = ({
  amount,
  currency = 'USD',
  symbol = '$',
  hasSuffix = false,
  decimalValue = false,
  percentageValue = false,
}: {
  amount: number;
  currency?: string;
  symbol?: string;
  hasSuffix?: boolean;
  decimalValue?: boolean;
  percentageValue?: boolean;
}): string => {
  const dollarUSLocale = Intl.NumberFormat('en-US');
  const suffix = hasSuffix ? ` ${currency || ''}` : '';
  const isNegative = amount < 0;
  const isValid = !isNaN(amount);
  const value = isValid ? Math.abs(amount) : 0;
  const label = `${decimalValue ? value.toFixed(2) : value}${
    percentageValue ? ' %' : ''
  }${suffix}`;
  const formattedLabel = percentageValue
    ? label
    : dollarUSLocale.format(+label);
  const negativeLabel = `(${
    percentageValue ? '' : symbol || ''
  }${formattedLabel})`;
  return isNegative
    ? negativeLabel
    : `${percentageValue ? '' : symbol || ''}${formattedLabel}`;
};

export const getQuarterLabel = ({
  frequency_number,
  start_date,
  end_date,
}: MonthsEntityOrQuartersEntity) => {
  const start = moment(start_date).format('MM/YY');
  const quarter = frequency_number;
  const end = moment(end_date).format('MM/YY');
  let label;
  if (quarter === 1) {
    label = `First Quarter`;
  } else if (quarter === 2) {
    label = 'Second Quarter';
  } else if (quarter === 3) {
    label = 'Third Quarter';
  } else if (quarter === 4) {
    label = 'Fourth Quarter';
  }
  return `${label} (${start} - ${end})`;
};
export const convertResToExportUrl = (res): string => {
  const url = window.URL.createObjectURL(
    new Blob([res.data], {
      type: res.headers['content-type'],
    })
  );
  return url;
};
export const downloadPdf = (url: string, fileName?: string) => {
  const link = document.createElement('a');
  link.href = url;
  if (fileName) link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getDateRangeFromString = (inputString: string) => {
  const regex = /(\d{4})(\d{2})(\d{2})-(\d{4})(\d{2})(\d{2})/;
  const match = inputString.match(regex);

  if (match) {
    const startDate = `${match[1]}-${match[2]}-${match[3]}`;
    const endDate = `${match[4]}-${match[5]}-${match[6]}`;
    return { startDate, endDate };
  } else {
    return null;
  }
};

export const getCategoryFromString = (inputString: string) => {
  const categoryLetter = inputString.split('-')[3];
  if (categoryLetter === 'm') return 'MONTHLY';
  else return 'QUARTERLY';
};
export const getActiveTabFromString = (inputString: string) => {
  const activeTabLetter = inputString.split('-')[6];
  if (activeTabLetter === 'cf') return { id: 2, name: 'cash_flow' };
  else if (activeTabLetter === 'bs') return { id: 1, name: 'balance_sheet' };
  else return { id: 0, name: 'income_statement' };
};

//customized input field

export const WithStylesTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#A2A2A3',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#345DF8',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#D4D4D5',
    },
  },
  input: {
    '& .webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px red inset',
    },
  },
})(TextField);

// Month name from month number

export const toMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber);
  const isAfter = moment(date).isAfter();
  if (isAfter) return moment(date).subtract(1, 'years').format('YYYY-MM-DD');
  else return moment(date).format('YYYY-MM-DD');
};
