import { useEffect, useCallback } from 'react';

import { AuthMethod } from '@puretax/data-access';
import {
  getActiveTabFromString,
  getCategoryFromString,
  getDateRangeFromString,
  getErrorMessages,
} from '@puretax/shared';
import { useSnackbar } from '@puretax/ui';
import { withRouter, RouteComponentProps } from 'react-router-dom';

export type CommentVerifyLinkProps = RouteComponentProps<MatchParams>;

interface MatchParams {
  key: string;
}
const CommentVerifyLink = (props: CommentVerifyLinkProps) => {
  const { showSnackbar } = useSnackbar();
  const key = props.match.params.key;
  const dateRange = getDateRangeFromString(key);
  const category = getCategoryFromString(key);
  const activeTab = getActiveTabFromString(key);
  const commentId = key.split('-')[7];
  const clientId = +key.split('-')[1];

  useEffect(() => {
    if (key) {
      props.history.push({
        pathname: `/login`,
        state: {
          data: {
            start_date: dateRange.startDate,
            end_date: dateRange.endDate,
            frequency_type: category,
            activeTab: activeTab,
            commentId: commentId,
            key: key,
          },
          clientId: clientId,
          isFinancial: true,
        },
      });
    }
  }, [key]);
  return null;
};

export default withRouter(CommentVerifyLink);
