import {
  AccountantNoDataIcon,
  BillPayNoDataIcon,
  ClientNoDataIcon,
  RecordsNoDataIcon,
  TaxNoDataIcon,
  TodoNoDataIcon,
} from '../icons/no-data-icons';

export const NoDataComponentsInfo = {
  client: {
    icon: ClientNoDataIcon,
    title: 'You have no clients',
    desc_head:
      'Click below to setup and onboard new clients, and invite client users to the portal',
  },
  clientUsers: {
    icon: ClientNoDataIcon,
    title: 'You have no client users',
    desc_head: 'Click below to invite client users to the portal',
  },
  accountant: {
    icon: AccountantNoDataIcon,
    title: 'You have no accountants',
    desc_head:
      'Click below to create an individual user profile for each accountant and invite them to the portal',
  },
  todo: {
    icon: TodoNoDataIcon,
    title: 'Tasks cleared',
    desc_head:
      'You are all set for now. If your accountant needs anything, you will receive a notification email.',
  },
  taxTodo: {
    icon: TodoNoDataIcon,
    desc_head:
      'You are all set for now. If your accountant needs anything, you will receive a notification email.',
  },
  taxDocs: {
    icon: RecordsNoDataIcon,
    desc_head:
      'Your accountant will upload finished tax forms when done. To upload a document, click on the button at the top right.',
  },
  Records: {
    icon: RecordsNoDataIcon,
    title: 'No records found',
    desc_head: 'We found no data based on your search parameters',
  },
  tax: {
    icon: TaxNoDataIcon,
    title: 'You have no tax added',
    desc_head: '',
  },
  work: {
    icon: TaxNoDataIcon,
    title: 'You have no work added',
    desc_head:
      'You are all set for now. If your accountant needs anything, you will receive a notification email.',
  },
  bills_pay: {
    icon: BillPayNoDataIcon,
    title: 'No records found',
    desc_head: `There's nothing to see here just yet. Click below to upload.`,
  },
  client_user: {
    icon: ClientNoDataIcon,
    title: 'No data found',
    desc_head: '',
  },
  financial: {
    icon: ClientNoDataIcon,
    title: 'No data found',
    desc_head: '',
  },
};
