export const userMsgs = {
  //password
  passwordUpdated: 'Password has been changed',
  passwordNotEntered: 'Enter your password',
  passwordNotMatched: 'Your new password does not match',
  passwordIncorrect: 'The password you have entered is incorrect',
  passwordLength: 'Password must be at least 8 characters long',

  //email
  emailNotValid: 'Enter a valid email address',
  emailNotEntered: 'Enter your email',
  emailNotMatching: 'Email confirmation does not match entered email',
  sameEmailEntry: 'Enter a new email',

  //phone
  phoneNotValid: 'Enter a 10-digit US phone number',
  containSpecialChars: 'Name contain special characters',

  //url
  urlNotValid: 'Enter a valid URL',

  //zip
  zipNotValid: 'Enter a valid zip code',

  //ein
  einNotValid: 'Enter a valid federal EIN',

  //serverError massage
  user_already_exist: 'Looks like you already have an account. Please log in',
  email_or_password_incorrect: 'You entered an incorrect email or password',
  commonError: 'Something went wrong. please try again after sometime',
  password_too_common:
    'Password must contain at least one letter, one number and one special character',
  user_not_found:
    'Sorry, we couldn’t find an account for that email If you need help, feel free to slack your accountant',

  //integration
  disConnection: 'Quick books disconnected successfully',

  //global error massage
  appGlobalErrorTitle: 'Oops! Something went wrong',
  appGlobalErrorDesc: `We're working on getting this fixed as soon as we can`,
  unsavedData: 'Changes that you made may not be saved',
  cannotUndone: 'This action cannot be undone',

  accountant: {
    add: 'Accountant added successfully',
    update: 'Accountant updated successfully',
    delete: 'Accountant deleted successfully',
    archive: 'Accountant archived successfully',
    delete_confirm:
      'Are you sure you want to delete this accountant? Please confirm',
    delete_confirm_title: 'Delete Accountant',
    deactivate_confirm_title: 'Deactivate Accountant',
    deactivate_confirm:
      'Are you sure you want to deactivate this accountant? Please confirm',
    deactivate_success: 'Accountant deactivated successfully',
    activate_confirm_title: 'Activate Accountant',
    activate_confirm:
      'Are you sure you want to activate this accountant? Please confirm',
    activate_success: 'Accountant activated successfully',
  },
  client: {
    add: 'Client added successfully',
    update: 'Client details have been updated',
    delete: 'Client deleted successfully',
    archive: 'Client archived successfully',
    delete_confirm:
      'Are you sure you want to delete this client? Please confirm',
    delete_confirm_title: 'Delete Client',
    invited: 'User(s) have been invited',
    userUpdated: 'User updated successfully',
    invite_email_already_exist: 'A user with this email address already exists',
    deactivate_confirm_title: 'Deactivate Client',
    deactivate_confirm:
      'Are you sure you want to deactivate this client? Please confirm',
    deactivate_success: 'Client deactivated successfully',
    activate_confirm_title: 'Activate Client',
    activate_confirm:
      'Are you sure you want to activate this client? Please confirm',
    activate_success: 'Client activated successfully',
  },
  clientUser: {
    delete: 'Client user has been deleted',
    delete_confirm_title: 'Delete User',
    delete_confirm:
      'Are you sure you want to delete this client user? Please confirm',
  },
  todo: {
    add: 'Task has been created',
    update: 'Task has been updated',

    delete: 'Task has been deleted',
    delete_confirm: 'Are you sure you want to delete this task? Please confirm',
    delete_confirm_title: 'Delete Task',

    archive: 'Task has been archived',
    archive_confirm_title: 'Archive Task',
    archive_confirm:
      'Are you sure you want to archive this task? Please confirm',
    archive_confirm_button: 'Archive',

    restore_confirm_button: 'Restore',
    restore: 'Task has been restored',
    restore_confirm_title: 'Restore Task',
    restore_confirm:
      'Are you sure you want to restore this task? Please confirm',

    mark_as_complete: 'Task has been marked as completed',
    mark_as_complete_confirm_title: 'Mark Complete',
    mark_as_complete_confirm:
      'Are you sure you want to mark this task as completed?  Please confirm',
    mark_as_complete_confirm_button: 'Complete',

    mark_as_incomplete: 'Task has been reopened',
    mark_as_incomplete_confirm_title: 'Reopen Task',
    mark_as_incomplete_confirm:
      'Are you sure you want to reopen this task? Please confirm',
    mark_as_incomplete_confirm_button: 'Reopen',
    notify: 'Reminder email has been sent',
  },
  work: {
    add: 'Work has been created',
    update: 'Work has been updated',
    delete: 'Work has been deleted',
    archive: 'Work has been archived',
    restore: 'Work has been restored',
    delete_confirm:
      'Are you sure you would like to delete this work? This action will delete all associated documents and task items',
    delete_confirm_title: 'Delete Work',

    restore_confirm_title: 'Restore Work',
    restore_confirm: 'Are you sure you would like to restore this work?',
    archive_confirm_title: 'Archive Work',
    archive_confirm:
      'Are you sure you want to archive this work? Please confirm',
    reopen_confirm_title: 'Reopen Work',
    reopen_confirm: 'Are you sure you want to reopen this work? Please confirm',
    reopen_confirm_button: 'Reopen',
  },
  folder: {
    add: 'New folder has been created',
    move: 'Document has been moved',
    remove: 'Document has been removed from folder',
    rename: 'Folder has been renamed',
  },
  status: { update: 'Status has been updated' },
  uploads: {
    uploaded: 'Document(s) have been uploaded',
    archived: 'Document has been archived',
    restored: 'Document has been Restored',
    delete: 'Document has been deleted',
    rename: 'Document has been renamed',
    download: 'Document has been downloaded',
    downloads: 'Documents have been downloaded',
    noFileToDownload: 'Work has no documents to download',
    delete_confirm:
      'Are you sure you want to delete this upload? Please confirm',
    delete_confirm_title: 'Delete Upload',

    archive_confirm_title: 'Archive Upload',
    archive_confirm:
      'Are you sure you want to archive this upload? Please confirm',
    archive_confirm_button: 'Archive',

    restore: 'Upload restored successfully',
    restore_confirm_title: 'Restore Upload',
    restore_confirm:
      'Are you sure you want to restore this upload? Please confirm',
    restore_confirm_button: 'Restore',
  },
  billPay: {
    uploaded:
      'Your document(s) have been uploaded and are in queue for processing',
    restored: 'document(s) have been restored',
    archived: 'document(s) have been archived',
    deleted: 'document(s) have been deleted',
  },
  file: {
    uploaded: 'Attachment has been uploaded',
    archived: 'Attachment has been archived',
    restored: 'Attachment has been restored',
    delete: 'Attachment has been deleted',
    archive_confirm_title: 'Archive File',
    archive_confirm:
      'Are you sure you want to archive this file? Please confirm',
    archive_confirm_button: 'Archive',

    delete_confirm_title: 'Delete File',
    delete_confirm: 'Are you sure you want to delete this file? Please confirm',
  },
  record: {
    uploaded: 'Record uploaded successfully',
    archived: 'Record archived successfully',
    restored: 'Record restored successfully',
    delete: 'Record deleted successfully',
    delete_confirm_title: 'Delete Record',
    delete_confirm:
      'Are you sure you want to delete this record? Please confirm',
  },

  comment: {
    delete: 'Comment has been deleted',
    delete_confirm_title: 'Delete Comment',
    delete_confirm:
      'Are you sure you want to delete this Comment? Please confirm',
    delete_confirm_button: 'Delete',
    update: 'Comment has been updated',
  },

  //work error messages

  workAdd: 'Please enter your work title',
  selectFiscalYearEnd: 'Please select fiscal year end (MM/DD)',
  selectFiscalYear: 'Please select the fiscal year (YYYY)',
  selectFiscalMonth: 'Please select the fiscal month',
  addAssigned: 'Please select your assigned client user',
  taxAgency: 'Please enter your tax agency',
  taxJurisdiction: 'Please select the tax jurisdiction',
  dueOn: 'Due date should be greater than today',

  //Quickbooks
  disconnectConfirmTitle: 'Disconnect from QuickBooks',
  disconnectConfirm: 'Are you sure you wish to disconnect QuickBooks',
  disconnectBtn: 'Disconnect',

  //otp
  resendOtpSuccess: 'Sent. Please check your email',

  //Idle time out messages
  idle_confirm:
    "You've been idle for sometime. For your security, we'll automatically sign you out in approximately 1 minute. Choose 'Stay signed in' to continue or 'Sign out' if you're done.",
  idle_title: 'Are you still there?',
  idle_confirm_button: 'Stay signed in',
  idle_cancel_button: 'Sign out',

  //help icon
  flash_notification:
    'We are updating our support docs. For now, please slack your accountant for assistance.',

  //financial
  send_report: 'Flash report has been emailed to the recipients',
};
