import { AxiosRes, CommentItemType, getQueryString } from '@puretax/shared';
import { puretax_instance } from '../axios';
import { getDefaultClient } from '../local-storage';

const client = () => getDefaultClient();
export const CommentBoxMethod = {
  fetchCommentList: async (filters): AxiosRes<CommentItemType[]> => {
    const queryParams = getQueryString(filters);
    return await puretax_instance.get(`/${client()}/comments/${queryParams}`);
  },
  postComment: async (payload): AxiosRes<CommentItemType> =>
    await puretax_instance.post(`/${client()}/comments/`, payload),
  deleteComment: async (id: number): AxiosRes<null> =>
    await puretax_instance.delete(`/${client()}/comments/${id}/`),
  editComment: async (payload: any): AxiosRes<null> => {
    const { id, ...restPayload } = payload;
    return await puretax_instance.patch(
      `/${client()}/comments/${id}/`,
      restPayload
    );
  },
  toggleComment: async (payload): AxiosRes<any> =>
    await puretax_instance.post(
      `/${client()}/comments/toggle-status/`,
      payload
    ),
  deleteThread: async (filters): AxiosRes<any> => {
    const queryParams = getQueryString(filters);
    return await puretax_instance.get(
      `/${client()}/comments/delete-thread/${queryParams}`
    );
  },
};
