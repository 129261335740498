// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".mui-popper_root__vLv8u {\n  position: relative;\n  border-radius: 6px;\n  outline: none;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: fit-content;\n  block-size: fit-content;\n  width: -moz-fit-content;\n}\n\n.mui-popper_paperClassName__ayhPf {\n  border-radius: 8px !important;\n  border: 1px solid #e1e4e8;\n  box-shadow: 0px 4px 12px 0px rgba(18, 18, 21, 0.08), 0px 10px 22px 0px rgba(18, 18, 21, 0.1) !important;\n}", ""]);
// Exports
exports.locals = {
	"root": "mui-popper_root__vLv8u",
	"paperClassName": "mui-popper_paperClassName__ayhPf"
};
module.exports = exports;
