/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useCallback, useEffect, useRef } from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import { getFullName, User } from '@puretax/shared';
import * as Editor from 'ckeditor5-custom-build/build/ckeditor';
import './rich-editor.css';

import { Spin } from '../spin/spin';
import { imageUploadEditing, MentionCustomization } from './utils';
import { FileMethod } from '@puretax/data-access';
import axios from 'axios';

interface RichEditorProps {
  onChange: (value: string) => void;
  className?: string;
  placeholder?: string;
  value?: string;
  isReadOnly?: boolean;
  onBlur?: (value: string) => void;
  onFocus?: () => void;
  mentionList?: User[];
  isUploadImage?: (value: boolean) => void;
  showMentions?: boolean;
  ref?: any;
}

export const RichEditor = (props: RichEditorProps) => {
  //MENTION
  const getUserList = useCallback(() => {
    const formattedUserList = props.mentionList?.map((item) => {
      const fullName = getFullName(item?.first_name, item?.last_name);
      const _item = {
        id: `@${fullName}`,
        userId: item.id,
        _name: fullName,
        link: item.email,
      };
      return _item;
    });
    return formattedUserList;
  }, [props.mentionList]);

  const getFeedItems = useCallback(
    (queryText) => {
      const items = getUserList?.();
      return new Promise((resolve) => {
        setTimeout(() => {
          const itemsToDisplay = items?.filter(isItemMatching).slice(0, 10);
          resolve(itemsToDisplay);
        }, 100);
      });
      function isItemMatching(item) {
        const searchString = queryText?.toLowerCase();
        return item._name.toLowerCase().includes(searchString);
      }
    },
    [getUserList]
  );

  function uploadAdapter(loader) {
    return {
      upload: async () => {
        props.isUploadImage?.(true);
        return new Promise((resolve, reject) => {
          loader.file.then(async (file) => {
            const fileRes = await FileMethod.postFile({
              name: file.name,
              section: 'editor-files',
              size: file.size,
            });
            await axios.put(fileRes.data.url, file);
            const res = await FileMethod.getUploadUrl(fileRes.data.id);
            props.isUploadImage?.(false);
            resolve({
              urls: {
                default: res.data.url,
              },
              alt: res.data.id,
            });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const editorConfig = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      '|',
      'imageUpload',
      'mediaEmbed',
      'undo',
      'redo',
    ],
    mention: {
      feeds: [
        {
          marker: '@',
          feed: getFeedItems,
        },
      ],
    },
    image: {
      toolbar: [],
    },
    link: {
      defaultProtocol: 'https://',
      decorators: {
        addTargetToExternalLinks: {
          mode: 'automatic',
          callback: (url) => /^(https?:)?\/\//.test(url),
          attributes: {
            target: '_blank',
            rel: 'noopener noreferrer',
          },
        },
      },
    },
    extraPlugins: [MentionCustomization, uploadPlugin, imageUploadEditing],
    placeholder: props.placeholder,
    isReadOnly: props.isReadOnly,
    mediaEmbed: {
      extraProviders: [
        {
          name: 'allow-all',
          url: /(?:https?:\/\/)?(?:www\.)?loom\.com\/share\/([a-f0-9]{32})/i,
        },
      ],
    },
  };

  if (!props.isReadOnly && !props.mentionList && props.showMentions)
    return <Spin />;
  return (
    <CKEditor
      editor={Editor}
      disabled={props.isReadOnly}
      config={editorConfig}
      className={props.className}
      data={props.value}
      onChange={(e, editor) => {
        const data = editor.getData();
        props.onChange(data);
      }}
      onBlur={(e, editor) => {
        const data = editor.getData();
        props.onBlur(data);
      }}
      onFocus={props.onFocus}
      ref={props.ref}
    />
  );
};
export default RichEditor;
