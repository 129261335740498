import { ReactNode } from 'react';

import { Columns, DataSource } from '@puretax/ui';
import clsx from 'clsx';

import { TD } from '..';
import styles from './table-list.module.scss';

interface Props {
  record: DataSource;
  column: Columns;
  index: number;
  onRow?: any;
  showFinancialDataTable: boolean;
  dataSource: DataSource[];
  collabkitObjectId: string;
  keyValue: string;
  cellAppend?: (id: string, cellIndex: number) => ReactNode;
}
export const GetCellContent = ({
  record,
  column,
  index,
  showFinancialDataTable,
  onRow,
  dataSource,
  collabkitObjectId,
  keyValue,
  cellAppend,
}: Props) => {
  return (
    <TD
      key={index}
      className={clsx(
        styles.col,
        column.className,
        showFinancialDataTable && styles.financialDataCol
      )}
      onClick={onRow}
    >
      {column.render
        ? column.render?.(record[keyValue], record.data, dataSource)
        : typeof record[keyValue] === 'string'
        ? record[keyValue]
        : null}
      {cellAppend && cellAppend(collabkitObjectId, index)}
    </TD>
  );
};
