import React, { ReactNode } from 'react';

import clsx from 'clsx';

import { getDefaultClient } from '@puretax/data-access';
import { AccountingType, User, environment } from '@puretax/shared';
import { TBody, THead, TRow } from '..';
import { TH, Table } from '../table/table';
import { GetCellContent } from './cell-content';
import styles from './table-list.module.scss';

interface TableListProps {
  dataSource?: DataSource[];
  columns?: Columns[];
  onRowStyle?: (record) => {
    className?: string;
  };
  isCommentable?: boolean;
  onRow?: (
    record,
    rowIndex: string,
    cellIndex?: number
  ) => {
    onClick?: (e: React.MouseEvent<HTMLTableRowElement>) => void;
  };
  className?: string;
  selectedRowHover?: (record) => {
    className?: string;
  };
  showFinancialDataTable?: boolean;
  dataType?: any;
  reportData?: AccountingType;
  mentionUsers?: User[];
  parentIdData?: any;
  cellAppend?: (id: string, cellIndex: number) => ReactNode;
}

export interface DataSource {
  [key: string]: any;
}
export interface Columns {
  title: string;
  dataIndex: string;
  key: string;
  className?: string;
  render?: (title: string, data, dataSource) => ReactNode;
}

export const TableList = (props: TableListProps) => {
  const codeMap = {
    dev: 'd',
    prod: 'p',
    MONTHLY: 'm',
    QUARTERLY: 'q',
    income_statement: 'is',
    balance_sheet: 'bs',
    cash_flow: 'cf',
  };

  const getAbbreviation = (code) => {
    return codeMap[code];
  };

  const getCellContent = (record: DataSource, column: Columns, index: any) => {
    return Object.keys(record).map((key, cellIndex) => {
      if (column.dataIndex !== key) return null;
      const { onClick } = props.onRow(record.data, column.dataIndex, cellIndex);
      const commentBoxId = `${getAbbreviation(
        environment.ENV
      )}-${getDefaultClient()}-financial-${getAbbreviation(
        props.reportData?.frequency_type
      )}-${props.reportData?.start_date.replace(
        /-/g,
        ''
      )}-${props.reportData?.end_date.replace(/-/g, '')}-${getAbbreviation(
        props.dataType
      )}-${record.data.id}-${record.data.nesting_level}-${cellIndex}-${
        record.data?.parentId
      }`;
      return (
        <GetCellContent
          record={record}
          column={column}
          index={cellIndex}
          onRow={onClick}
          showFinancialDataTable={props.showFinancialDataTable}
          dataSource={props.dataSource}
          collabkitObjectId={commentBoxId}
          keyValue={key}
          cellAppend={props.cellAppend}
        />
      );
    });
  };
  return (
    <Table
      className={clsx(
        styles.table,
        props.className,
        props.showFinancialDataTable && styles.financialDataTable
      )}
    >
      <THead className={styles.thead}>
        <TRow className={styles.trow}>
          {props.columns.map((item) => {
            return (
              <TH
                key={item.key}
                className={clsx(
                  styles.col,
                  props.showFinancialDataTable && styles.financialTableHeader
                )}
              >
                {item.title}
              </TH>
            );
          })}
        </TRow>
      </THead>
      <TBody
        className={clsx(
          styles.tbody,
          props?.isCommentable && styles.financialTbody
        )}
      >
        {props.dataSource.map((record, index) => {
          return (
            <TRow
              key={record.key}
              className={clsx(
                styles.trow,
                record.className,
                props.onRowStyle && props?.onRowStyle(record.data)?.className,
                props.selectedRowHover &&
                  styles[props?.selectedRowHover(record.data)?.className],
                props.showFinancialDataTable && styles.financialTableRow
              )}
            >
              {props.columns.map((column) =>
                getCellContent(record, column, index)
              )}
            </TRow>
          );
        })}
      </TBody>
    </Table>
  );
};
export default TableList;
