import React from 'react';
type NoDataIconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const ClientNoDataIcon = (props: NoDataIconsProps) => {
  return (
    <svg
      {...props}
      width="160"
      height="160"
      viewBox="0 0 160 160"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <circle id="a" cx="80" cy="80" r="80" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#ECECED" xlinkHref="#a" />
        <ellipse
          fill="#D4D4D5"
          mask="url(#b)"
          cx="80"
          cy="150.4"
          rx="77.257"
          ry="16"
        />
        <path
          d="m90.792 96.092 11.57 3.177a7.668 7.668 0 0 1 5.638 7.394v26.648a3.66 3.66 0 0 1-2.46 3.457l-2.888 1.002-11.86-41.678Z"
          fill="#98989A"
        />
        <path
          d="m90.792 96.52 9.635 2.457a7.668 7.668 0 0 1 5.773 7.43v26.763a4.6 4.6 0 0 1-4.6 4.6H90.792V96.52Z"
          fill="#727275"
        />
        <path
          d="m77.243 86.645 6.34-.702a7.668 7.668 0 0 1 8.511 7.62v41.993a3 3 0 0 1-2.115 2.867l-3.93 1.213v-45.24l-8.806-7.751Z"
          fill="#98989A"
        />
        <path
          d="m59.823 88.173 20.132-2.23a7.668 7.668 0 0 1 8.512 7.62V136a4 4 0 0 1-4 4H57.6A4.6 4.6 0 0 1 53 135.4V95.793c0-3.908 2.94-7.191 6.823-7.621Z"
          fill="#BCBCBC"
        />
        <path
          d="M66.28 109.616c1.236-.05 2.237.945 2.237 2.221v4.851c0 1.277-1.001 2.317-2.237 2.324l-4.394.024a2.222 2.222 0 0 1-2.236-2.225v-4.694a2.348 2.348 0 0 1 2.236-2.326l4.394-.175Zm13.3-.532c1.236-.05 2.237.995 2.237 2.334v5.086c0 1.338-1.001 2.429-2.236 2.436l-4.394.023c-1.236.007-2.237-1.039-2.237-2.336v-4.93c0-1.297 1.001-2.388 2.237-2.438l4.394-.175Zm-13.3-13.562c1.236-.114 2.237.829 2.237 2.105v4.851c0 1.277-1.001 2.369-2.237 2.44l-4.394.251a2.089 2.089 0 0 1-2.236-2.108v-4.694c0-1.235 1.001-2.328 2.236-2.442l4.394-.403Zm13.3-1.222c1.236-.114 2.237.88 2.237 2.218v5.086c0 1.338-1.001 2.48-2.236 2.552l-4.394.251c-1.236.071-2.237-.923-2.237-2.22v-4.93c0-1.297 1.001-2.44 2.237-2.554l4.394-.403Z"
          fill="#ECECED"
        />
        <circle fill="#D8D8D8" cx="130" cy="92" r="8" />
        <g fillRule="nonzero">
          <path
            d="M40.872 76.962c5.501-.481 9.571-5.332 9.09-10.834-.481-5.501-5.332-9.571-10.834-9.09-5.501.481-9.571 5.332-9.09 10.834.481 5.501 5.332 9.571 10.834 9.09Z"
            fill="#D4D4D5"
          />
          <path
            d="M42.865 67.658a2.204 2.204 0 1 1 .384 4.39l-5.572.488a2.204 2.204 0 0 1-.384-4.39l5.572-.488Zm-3.288-5.954c1.375-.12 2.59.92 2.712 2.323.123 1.403-.893 2.638-2.268 2.758-1.375.12-2.59-.92-2.713-2.323-.122-1.403.893-2.637 2.269-2.758Z"
            fill="#F4F4F6"
            opacity=".75"
          />
        </g>
        <g fillRule="nonzero">
          <path
            d="M94.05 49.88c8.77 1.078 16.754-5.16 17.83-13.93 1.078-8.77-5.16-16.754-13.93-17.83-8.77-1.078-16.754 5.16-17.83 13.93-1.078 8.77 5.16 16.754 13.93 17.83Z"
            fill="#D4D4D5"
          />
          <path
            d="M100.264 35.983a3.526 3.526 0 0 1-.86 6.999l-8.88-1.09a3.526 3.526 0 0 1 .859-7l8.881 1.09ZM97.1 25.57c2.193.27 3.747 2.3 3.473 4.537-.275 2.237-2.275 3.831-4.468 3.562-2.192-.269-3.747-2.3-3.473-4.537.275-2.236 2.275-3.831 4.468-3.562Z"
            fill="#F4F4F6"
            opacity=".75"
          />
        </g>
        <path
          d="M48.278 30.38c2.153.482 3.82 2.206 4.375 4.344.07.138.208.276.347.276.208 0 .347-.138.347-.276.486-2.138 2.222-3.793 4.375-4.345.14-.069.278-.207.278-.345 0-.206-.139-.344-.278-.344-2.153-.552-3.82-2.276-4.375-4.414-.07-.138-.208-.276-.347-.276-.208 0-.347.138-.347.276-.556 2.138-2.222 3.862-4.375 4.345-.14.069-.278.207-.278.345 0 .206.07.344.278.413ZM30.833 94.208c.084-.041.167-.125.167-.208 0-.125-.083-.208-.167-.208a3.644 3.644 0 0 1-2.625-2.625C28.167 91.083 28.083 91 28 91c-.125 0-.208.083-.208.167a3.644 3.644 0 0 1-2.625 2.625c-.084.041-.167.125-.167.208 0 .125.083.208.167.208 1.291.292 2.291 1.334 2.625 2.625.041.084.125.167.208.167.125 0 .208-.083.208-.167.334-1.291 1.334-2.333 2.625-2.625ZM138.833 46.208c.084-.041.167-.125.167-.208 0-.125-.083-.208-.167-.208a3.644 3.644 0 0 1-2.625-2.625c-.041-.084-.125-.167-.208-.167-.125 0-.208.083-.208.167a3.644 3.644 0 0 1-2.625 2.625c-.084.041-.167.125-.167.208 0 .125.083.208.167.208 1.291.292 2.291 1.334 2.625 2.625.041.084.125.167.208.167.125 0 .208-.083.208-.167.334-1.291 1.334-2.333 2.625-2.625ZM103.778 78.722a4.858 4.858 0 0 1-3.5-3.5c-.056-.11-.167-.222-.278-.222-.167 0-.278.111-.278.222a4.858 4.858 0 0 1-3.5 3.5c-.11.056-.222.167-.222.278 0 .167.111.278.222.278 1.722.389 3.056 1.778 3.5 3.5.056.11.167.222.278.222.167 0 .278-.111.278-.222.389-1.722 1.778-3.056 3.5-3.5.11-.056.222-.167.222-.278 0-.111-.111-.222-.222-.278Z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
export const AccountantNoDataIcon = (props: NoDataIconsProps) => {
  return (
    <svg
      {...props}
      width="160"
      height="160"
      viewBox="0 0 160 160"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <circle id="a" cx="80" cy="80" r="80" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <use fill="#ECECED" xlinkHref="#a" />
          <ellipse
            fill="#D4D4D5"
            mask="url(#b)"
            cx="80"
            cy="150.4"
            rx="77.257"
            ry="16"
          />
        </g>
        <g fillRule="nonzero">
          <path
            d="M89.729 113.57c6.529-.023 12.446 5.216 12.092 13.179-.353 7.962-7.081 11.037-7.958 11.037-2.521.672-4.253.985-5.194.938l-28.915-1.44c-6.53-.35-11.872-5.678-11.933-11.897-.06-6.22 5.023-11.693 11.552-11.715l30.356-.103ZM72.726 80.016c.405-.034 2.694.1 3.09.103 6.899.067 12.36 5.676 12.426 13.186.07 7.941-4.56 12.734-10.6 14.143-1.004.234-6.261.48-7.349.478-6.149-.013-8.99-6.823-9.051-13.244-.07-7.447 4.028-14.038 11.484-14.666Z"
            fill="#98989A"
          />
          <path
            d="M85.889 113.982c6.529-.022 11.871 5.694 11.932 12.767.061 7.073-5.183 12.521-11.712 12.17l-27.355-1.634c-6.53-.352-11.872-5.679-11.933-11.898-.06-6.22 5.183-11.28 11.712-11.302l27.356-.103Zm-15.275-33.92c7.456-.627 13.557 5.303 13.628 13.244.07 7.941-5.917 14.487-13.373 14.62-7.456.134-13.557-5.795-13.627-13.243-.07-7.447 5.916-13.993 13.372-14.62Z"
            fill="#BCBCBC"
          />
        </g>
        <path
          d="M104.754 109.112c2.934.12 5.25 2.195 5.246 5.397l-.03 22.991c-.004 3.203-1.897 4.76-5.216 5.272-1.14.152-2.004.228-2.592.228-.803 0-1.715-.391-2.743-.44l-9.96-.47c-2.876-.169-5.211-2.675-5.215-5.597l-.03-20.976c-.004-2.922 2.332-5.428 5.219-5.598 5.702-.434 10.013-.738 12.934-.91.49-.028 1.286.006 2.387.103Z"
          fill="#A4A2A7"
        />
        <path
          d="M101.567 109.018c2.887-.17 5.223 2.29 5.219 5.491l-.03 22.991c-.004 3.203-2.34 5.661-5.216 5.492l-15.294-.902c-2.877-.169-5.212-2.675-5.216-5.597L81 115.517c-.004-2.922 2.332-5.428 5.219-5.598l15.348-.9Z"
          fill="#727275"
        />
        <path
          d="M89.26 131.968c-1.706-.037-3.088 1.364-3.086 3.128v.044c.001 1.764 1.384 3.257 3.09 3.334 1.704.077 3.085-1.323 3.085-3.127v-.046c0-1.804-1.383-3.296-3.09-3.333ZM98.526 132.168c-1.706-.037-3.089 1.412-3.09 3.236v.046c0 1.824 1.382 3.365 3.087 3.442 1.705.077 3.088-1.372 3.09-3.236v-.046c0-1.864-1.381-3.405-3.087-3.442ZM89.256 123.836c-1.708.014-3.09 1.455-3.089 3.219v.044c.002 1.764 1.386 3.216 3.092 3.243 1.706.027 3.09-1.414 3.089-3.218v-.045c0-1.804-1.385-3.256-3.092-3.243ZM98.53 123.764c-1.707.013-3.092 1.503-3.092 3.326v.046c0 1.824 1.383 3.325 3.089 3.351 1.706.027 3.09-1.462 3.092-3.326v-.047c.001-1.864-1.381-3.364-3.089-3.35ZM88.246 114.122c-1.154.05-2.089 1.055-2.088 2.246l.002 2.12c0 1.19.937 2.138 2.09 2.115l11.286-.22c1.153-.023 2.09-1.062 2.09-2.32l.002-2.24c0-1.258-.934-2.238-2.088-2.188l-11.294.487Z"
          fill="#D4D4D5"
        />
        <path
          d="M63.278 59.38c2.153.482 3.82 2.206 4.375 4.344.07.138.208.276.347.276.208 0 .347-.138.347-.276.486-2.138 2.222-3.793 4.375-4.345.14-.069.278-.207.278-.345 0-.206-.139-.344-.278-.344-2.153-.552-3.82-2.276-4.375-4.414-.07-.138-.208-.276-.347-.276-.208 0-.347.138-.347.276-.556 2.138-2.222 3.862-4.375 4.345-.14.069-.278.207-.278.345 0 .206.07.344.278.413Z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <g stroke="#BCBCBC" strokeLinecap="round" strokeWidth="3.125">
          <path d="M32 72v10M37 77H27" />
        </g>
        <g stroke="#D8D8D8" strokeLinecap="round" strokeWidth="3.125">
          <path d="m119.536 87.464-7.072 7.072M119.536 94.536l-7.072-7.072" />
        </g>
        <g transform="translate(67 21)">
          <path
            stroke="#D8D8D8"
            strokeWidth="3"
            strokeLinecap="round"
            d="M10 6.531H0"
          />
          <circle fill="#D4D4D5" cx="5" cy="2" r="2" />
          <circle fill="#D4D4D5" cx="5" cy="11" r="2" />
        </g>
        <g transform="rotate(30 3.287 212.87)">
          <circle fill="#D4D4D5" cx="14" cy="14" r="14" />
          <path
            d="M14.638 5.118c-.559.016-1.038.472-1.102 1.08l-.047.447-.252.021c-2.123.21-3.61 1.546-3.823 3.577-.214 2.03.62 3.043 2.76 4.103l.858.4 1.02.457c1.338.624 1.828 1.124 1.742 1.944-.107 1.017-.966 1.595-2.298 1.455l-.263-.036c-.851-.146-1.43-.543-1.754-1.167l-.086-.187c-.207-.79-.93-1.277-1.687-1.144l-.073.013c-.712.126-1.202.842-1.093 1.6l.018.099.029.112.102.297c.512 1.355 1.517 2.302 2.948 2.787l.335.101-.048.458c-.069.658.375 1.244.992 1.31l.142.005c.558-.016 1.037-.472 1.101-1.08l.044-.428.267-.016c2.379-.19 4.054-1.661 4.29-3.912.24-2.274-.718-3.373-3.173-4.564l-1.454-.668c-1.443-.66-1.927-1.076-1.86-1.706.08-.767.892-1.247 1.995-1.13l.265.037c.596.109 1.047.364 1.38.775l.132.183c.343.706 1.127 1.026 1.835.749l.102-.04c.628-.246.958-.985.735-1.652l-.038-.1-.054-.112-.167-.275a4.735 4.735 0 0 0-2.365-1.896l-.371-.124.05-.458c.068-.658-.376-1.244-.993-1.31l-.141-.005Z"
            fillOpacity=".75"
            fill="#FFF"
            fillRule="nonzero"
          />
        </g>
        <path
          d="M27.833 99.208c.084-.041.167-.125.167-.208 0-.125-.083-.208-.167-.208a3.644 3.644 0 0 1-2.625-2.625C25.167 96.083 25.083 96 25 96c-.125 0-.208.083-.208.167a3.644 3.644 0 0 1-2.625 2.625c-.084.041-.167.125-.167.208 0 .125.083.208.167.208 1.291.292 2.291 1.334 2.625 2.625.041.084.125.167.208.167.125 0 .208-.083.208-.167.334-1.291 1.334-2.333 2.625-2.625ZM144.833 80.208c.084-.041.167-.125.167-.208 0-.125-.083-.208-.167-.208a3.644 3.644 0 0 1-2.625-2.625c-.041-.084-.125-.167-.208-.167-.125 0-.208.083-.208.167a3.644 3.644 0 0 1-2.625 2.625c-.084.041-.167.125-.167.208 0 .125.083.208.167.208 1.291.292 2.291 1.334 2.625 2.625.041.084.125.167.208.167.125 0 .208-.083.208-.167.334-1.291 1.334-2.333 2.625-2.625ZM40.778 33.722a4.858 4.858 0 0 1-3.5-3.5C37.222 30.112 37.11 30 37 30c-.167 0-.278.111-.278.222a4.858 4.858 0 0 1-3.5 3.5c-.11.056-.222.167-.222.278 0 .167.111.278.222.278 1.722.389 3.056 1.778 3.5 3.5.056.11.167.222.278.222.167 0 .278-.111.278-.222.389-1.722 1.778-3.056 3.5-3.5.11-.056.222-.167.222-.278 0-.111-.111-.222-.222-.278Z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
export const TodoNoDataIcon = (props: NoDataIconsProps) => {
  return (
    <svg
      {...props}
      width="160"
      height="160"
      viewBox="0 0 160 160"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <circle id="a" cx="80" cy="80" r="80" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <use fill="#ECECED" xlinkHref="#a" />
          <ellipse
            fill="#D4D4D5"
            mask="url(#b)"
            cx="80"
            cy="150.4"
            rx="77.257"
            ry="16"
          />
        </g>
        <path
          d="M95.279 138.118c1.239.042 4.755-.069 5.916-.332 6.607-1.496 10.83-7.662 11.084-15.192.268-7.939-4.71-14.014-10.908-15.123-.807-.144-3.932-.03-4.787 0-8.285.285-14.007 6.84-14.305 15.123-.299 8.285 4.716 15.24 13 15.524Z"
          fill="#98989A"
        />
        <path
          d="M94.738 138.515c8.284.284 15.242-6.662 15.54-15.515.3-8.853-6.174-15.8-14.458-15.515-8.285.284-15.243 7.23-15.541 15.515-.299 8.284 6.175 15.23 14.459 15.515Z"
          fill="#727275"
        />
        <path
          d="m100.734 118.322-.113.12-6.112 5.277-.806-.798-1.47-1.69a1.626 1.626 0 0 0-2.123.26c-.6.651-.617 1.656-.064 2.321l.11.12 3.279 3.535.24.2c.63.435 1.476.375 2.04-.166l.11-.115 7.266-6.613.181-.255c.418-.713.31-1.656-.297-2.228-.642-.604-1.613-.578-2.24.032Z"
          fill="#ECECED"
          opacity=".6"
        />
        <path
          d="M84.636 88.961c3.563.165 6.364 3.444 6.364 7.601v34.992c0 4.38-2.108 6.394-3.818 7.082-3.39.967-5.36 1.432-5.913 1.396-4.847-.315-12.017-.842-21.509-1.58-3.834-.25-6.942-3.708-6.942-7.725v-33.01c0-2.465 1.653-7.55 2.545-7.55 1.094 0 2.558.17 3.818 0l.018.48c.237 3.478 2.867 5.162 6.118 5.185l.228-.003 12.728-.4.475-.034c1.795-.208 2.697-2.352 2.707-6.434h3.18ZM62.364 90.02l-.001-.03v-1.75c0-.951.72-1.762 1.608-1.812l3.266-.18c.315-1.679-.663-4.467 1.197-4.49.734-.009 2.772.046 3.475 0 2.3-.15 4.219 1.557 4.672 3.97l3.266-.18c.888-.05 1.608.716 1.608 1.71v1.858c0 1.986-1.44 3.653-3.216 3.723l-12.66.499a2.945 2.945 0 0 1-1.392-.285l-.015-.01.015.01c-1.079-.514-1.823-1.665-1.823-3.033Z"
          fill="#98989A"
        />
        <path
          d="M80.818 88.961c3.563.165 6.364 3.444 6.364 7.601v35.992c0 4.38-3.108 7.727-6.942 7.478l-24.298-1.58c-3.834-.25-6.942-3.708-6.942-7.725v-33.01c0-3.813 2.8-7.07 6.363-7.55l.017.48c.238 3.478 2.868 5.162 6.119 5.185l.228-.003 12.728-.4.474-.034c3.218-.372 5.77-2.472 5.885-6.208l.004-.226ZM58.545 90.02v-1.78c0-.951.72-1.762 1.608-1.812l3.266-.18c.453-2.418 2.373-4.34 4.672-4.49 2.3-.15 4.219 1.557 4.672 3.97l3.266-.18c.888-.05 1.607.716 1.607 1.71v1.858c0 1.986-1.44 3.653-3.215 3.723l-12.66.499a2.945 2.945 0 0 1-1.392-.285l-.015-.01.015.01c-1.08-.514-1.824-1.665-1.824-3.033Z"
          fill="#BCBCBC"
        />
        <path
          d="M61.772 116.166c.598-.594 1.526-.595 2.138.026.58.588.683 1.534.284 2.235l-.173.25-3.733 4.446-.105.112c-.54.527-1.346.565-1.948.114l-.229-.207-1.95-2.09-.104-.124c-.529-.68-.512-1.684.06-2.32a1.491 1.491 0 0 1 2.026-.205l.224.2.77.82 2.631-3.139Zm6.344 2.352 11.136.202c.879.016 1.59.846 1.59 1.855 0 .897-.562 1.628-1.304 1.763l-.286.022-11.136-.292-.138-.01c-.814-.099-1.453-.875-1.453-1.8 0-.869.563-1.58 1.305-1.717l.286-.023Zm-6.344-14.13c.598-.61 1.526-.636 2.138-.032.58.573.683 1.516.284 2.228l-.173.255-3.733 4.547-.105.115a1.487 1.487 0 0 1-1.948.166l-.229-.2-1.95-2.039-.104-.12a1.838 1.838 0 0 1 .06-2.32c.542-.617 1.398-.71 2.026-.262l.224.195.77.798 2.631-3.21Zm17.48 2.082c.879-.008 1.59.803 1.59 1.812 0 .896-.562 1.643-1.304 1.798l-.286.03-11.136.01-.138-.007c-.814-.077-1.453-.836-1.453-1.761 0-.869.563-1.596 1.305-1.752l.286-.031Z"
          fill="#ECECED"
        />
        <path
          d="M37.727 60.6c.529-.306 1.194-.143 1.487.363.268.465.13 1.056-.303 1.387l-.125.083-6.7 3.868c-.529.306-1.195.143-1.487-.364-.268-.464-.13-1.056.303-1.386l.125-.084 6.7-3.868Zm-9.824 5.671c.529-.305 1.194-.142 1.487.364.268.465.13 1.056-.303 1.387l-.125.083-.067.039c-.529.305-1.194.142-1.487-.365-.268-.464-.129-1.056.303-1.386l.125-.083.067-.039Zm7.673-10.135c.528-.306 1.194-.142 1.486.364.268.464.13 1.056-.303 1.386l-.125.084-7.019 4.052c-.529.305-1.194.142-1.487-.364-.268-.464-.129-1.056.303-1.386l.125-.084 7.02-4.052Zm-10.144 5.856c.53-.305 1.195-.142 1.487.364.268.464.13 1.056-.303 1.386l-.125.084-.067.038c-.528.306-1.194.142-1.486-.364-.268-.464-.13-1.056.303-1.386l.125-.084.066-.038Zm7.673-10.136c.529-.305 1.194-.142 1.487.365.268.464.129 1.056-.303 1.386l-.125.083-7.02 4.053c-.528.305-1.194.142-1.486-.364-.268-.465-.13-1.056.303-1.387l.125-.083 7.019-4.053Zm-10.143 5.857c.529-.306 1.194-.142 1.486.364.268.464.13 1.056-.302 1.386l-.125.084-.067.038c-.529.305-1.194.142-1.487-.364-.268-.464-.13-1.056.303-1.386l.125-.084.067-.038Z"
          fill="#D8D8D8"
          fillRule="nonzero"
        />
        <path
          d="M131.232 74.01c4.895.864 8.2 5.333 7.38 9.982-.82 4.65-5.453 7.72-10.348 6.856-4.896-.863-8.2-5.332-7.38-9.982.82-4.65 5.453-7.719 10.348-6.855Zm-.36 3.13a.955.955 0 0 0-1.088.649l-.025.106-.772 4.377-.014.133a.931.931 0 0 0 .222.627l.08.084 3.438 3.185.11.089a.984.984 0 0 0 1.165-.037l.087-.079.09-.107a.92.92 0 0 0-.05-1.134l-.081-.085-3.061-2.838.685-3.88.013-.127a.956.956 0 0 0-.8-.964Zm3.45-5.532c.524.092.876.58.786 1.09a.947.947 0 0 1-.982.77l-.13-.014-5.17-.911a.951.951 0 0 1-.787-1.091.947.947 0 0 1 .982-.77l.13.014 5.17.912Z"
          fill="#D4D4D5"
        />
        <path
          d="M81 48c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16Z"
          fill="#D4D4D5"
          fillRule="nonzero"
        />
        <g opacity=".75">
          <path
            d="m84.676 25.864 2.445 2.425-4.769 4.731a.508.508 0 0 1-.218.128l-2.426.693a.508.508 0 0 1-.628-.63l.697-2.398a.508.508 0 0 1 .13-.22l4.769-4.73Zm3.545-1.435.33.327a1.49 1.49 0 0 1 0 2.115l-.884.877-2.446-2.426.902-.894a1.49 1.49 0 0 1 2.098 0Z"
            fill="#F4F4F6"
            fillRule="nonzero"
          />
          <path
            d="M88.062 31.546v3.77A3.69 3.69 0 0 1 84.368 39h-6.673A3.693 3.693 0 0 1 74 35.317v-6.583a3.685 3.685 0 0 1 3.691-3.683h3.824"
            stroke="#F4F4F6"
            strokeWidth="1.846"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M94.278 75.38c2.153.482 3.82 2.206 4.375 4.344.07.138.208.276.347.276.208 0 .347-.138.347-.276.486-2.138 2.222-3.793 4.375-4.345.14-.069.278-.207.278-.345 0-.206-.139-.344-.278-.344-2.153-.552-3.82-2.276-4.375-4.414-.07-.138-.208-.276-.347-.276-.208 0-.347.138-.347.276-.556 2.138-2.222 3.862-4.375 4.345-.14.069-.278.207-.278.345 0 .206.07.344.278.413ZM32.833 93.208c.084-.041.167-.125.167-.208 0-.125-.083-.208-.167-.208a3.644 3.644 0 0 1-2.625-2.625C30.167 90.083 30.083 90 30 90c-.125 0-.208.083-.208.167a3.644 3.644 0 0 1-2.625 2.625c-.084.041-.167.125-.167.208 0 .125.083.208.167.208 1.291.292 2.291 1.334 2.625 2.625.041.084.125.167.208.167.125 0 .208-.083.208-.167.334-1.291 1.334-2.333 2.625-2.625ZM122.833 43.208c.084-.041.167-.125.167-.208 0-.125-.083-.208-.167-.208a3.644 3.644 0 0 1-2.625-2.625c-.041-.084-.125-.167-.208-.167-.125 0-.208.083-.208.167a3.644 3.644 0 0 1-2.625 2.625c-.084.041-.167.125-.167.208 0 .125.083.208.167.208 1.291.292 2.291 1.334 2.625 2.625.041.084.125.167.208.167.125 0 .208-.083.208-.167.334-1.291 1.334-2.333 2.625-2.625ZM66.778 61.722a4.858 4.858 0 0 1-3.5-3.5C63.222 58.112 63.11 58 63 58c-.167 0-.278.111-.278.222a4.858 4.858 0 0 1-3.5 3.5c-.11.056-.222.167-.222.278 0 .167.111.278.222.278 1.722.389 3.056 1.778 3.5 3.5.056.11.167.222.278.222.167 0 .278-.111.278-.222.389-1.722 1.778-3.056 3.5-3.5.11-.056.222-.167.222-.278 0-.111-.111-.222-.222-.278Z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
export const RecordsNoDataIcon = (props: NoDataIconsProps) => {
  return (
    <svg
      {...props}
      width="160"
      height="160"
      viewBox="0 0 160 160"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <circle id="a" cx="80" cy="80" r="80" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <use fill="#ECECED" xlinkHref="#a" />
          <ellipse
            fill="#D4D4D5"
            mask="url(#b)"
            cx="80"
            cy="150.4"
            rx="77.257"
            ry="16"
          />
        </g>
        <path
          d="m85.556 105.663 19.97-.173c1.919-.016 3.474 1.885 3.474 4.248v23.525c0 1.666-.773 3.056-1.902 3.685-.472.263-1.758.496-2.585.739l-18.957-1.756c-1.918-.132-3.473-2.03-3.473-4.238v-22c0-1.422-.324-3.006.76-3.44.88-.352 2.03-.584 2.713-.59Z"
          fill="#98989A"
        />
        <path
          d="m83.556 106.162 19.97-.172c1.919-.017 3.474 1.885 3.474 4.247v23.526c0 2.362-1.555 4.17-3.473 4.039l-19.97-1.371c-1.919-.132-3.474-2.03-3.474-4.239v-22c0-2.209 1.555-4.013 3.473-4.03Z"
          fill="#727275"
        />
        <path
          d="M66.835 87.966c.867-.908 1.674-1.362 2.423-1.362h3.5c1.087.08 1.936 1.019 2.011 2.256l.005.178-.002 4.857.018.613c.277 4.77 3.762 8.475 8.066 8.5l.25-.003c2.13-.12 3.728-.198 4.792-.23l3.266-.101.317.016c1.256.168 1.939 1.266 2.014 2.576l.005.188v26.762c0 4.355-2.985 7.654-6.667 7.369l-26.666-2.068c-3.682-.286-6.667-3.651-6.667-7.517V95c0-3.866 2.985-6.231 6.667-6.517l6.668-.517Zm13.083.205c.276.163.54.352.79.565l12.328 10.863-3.252.242c-1.038.043-2.631.043-4.781 0l-.482-.007c-2.465-.173-4.305-2.054-4.41-4.88l-.005-.243-3.667-6.516c1.51 0 2.014-.024 3.48-.024Z"
          fill="#98989A"
        />
        <path
          d="m63.335 86.966 5.606-.362h.317c1.087.08 1.936 1.019 2.011 2.256l.005.178-.002 4.857.018.613c.277 4.77 3.762 8.475 8.066 8.5l.25-.003 8.06-.246.317.016c1.087.144 1.937 1.181 2.012 2.49l.005.19V132.215c0 4.355-2.985 7.654-6.667 7.369l-26.666-2.068C52.985 137.231 50 133.866 50 130V95c0-3.866 2.985-7.231 6.667-7.517l6.668-.517Zm13.083 1.205c.276.163.54.352.79.565l12.328 10.863c.084.078.167.158.248.24l-.117.002-8.061.336-.482-.008c-2.465-.173-4.408-2.389-4.514-5.214l-.004-.244.002-4.905-.021-.551a6.923 6.923 0 0 0-.169-1.084Z"
          fill="#BCBCBC"
        />
        <path
          d="m65.333 123.51 16 .531c1.105.037 2 1.106 2 2.388 0 1.283-.895 2.28-2 2.229l-16-.745c-1.104-.05-2-1.072-2-2.281s.896-2.16 2-2.122Zm-6.666-.222c1.104.037 2 1.038 2 2.237 0 1.2-.896 2.13-2 2.078-1.105-.051-2-1.053-2-2.237 0-1.184.895-2.115 2-2.078ZM81.333 112.5c1.105 0 2 1.04 2 2.322s-.895 2.31-2 2.295l-16-.213c-1.104-.015-2-1.007-2-2.215 0-1.21.896-2.189 2-2.189Zm-22.666 0c1.104 0 2 .972 2 2.17 0 1.2-.896 2.16-2 2.145-1.105-.014-2-.986-2-2.17 0-1.185.895-2.145 2-2.145Z"
          fill="#ECECED"
        />
        <path
          d="M27.133 72.472c.565.98 1.857 1.292 2.885.698l13.697-7.907c.716-.414 1.05-1.255.795-2.002l-2.185-6.402c-.364-1.067-1.559-1.607-2.669-1.206-.124.045-.244.101-.359.167l-.382-.662c-.566-.979-1.857-1.292-2.885-.698l-6.089 3.515c-.37.214-.803.289-1.214.21L27.3 57.91a1.769 1.769 0 0 0-1.214.21l-3.568 2.059c-1.028.594-1.403 1.869-.838 2.848l5.453 9.444Z"
          fill="#D4D4D5"
        />
        <path
          d="m27.064 64.951 2.954 8.22 13.697-7.908c.716-.414 1.05-1.255.795-2.002l-2.185-6.402c-.364-1.067-1.559-1.607-2.669-1.206-.124.045-.244.101-.359.167l-11.272 6.508a2.178 2.178 0 0 0-.96 2.623Z"
          fill="#F4F4F6"
        />
        <g
          stroke="#D4D4D5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.333"
        >
          <path d="m135 91-3.897-3.891M126.846 77a5.845 5.845 0 1 1 0 11.69 5.845 5.845 0 0 1 0-11.69h0Z" />
        </g>
        <path
          d="M98.222 53.757c8.702 1.534 17-4.276 18.535-12.979 1.534-8.702-4.276-17-12.979-18.535-8.702-1.534-17 4.276-18.535 12.979-1.534 8.702 4.276 17 12.979 18.535Z"
          fill="#D4D4D5"
          fillRule="nonzero"
        />
        <path
          d="M102.855 30.204c.547.096 1.26.542 1.58.982l3.337 4.565c.326.447.513 1.248.415 1.8l-1.16 6.58a3.004 3.004 0 0 1-3.48 2.441l-7.872-1.388A3 3 0 0 1 93.24 41.7l1.733-9.83a3.005 3.005 0 0 1 3.478-2.442l4.404.777Zm.825 11.315-6.402-1.129a.75.75 0 1 0-.26 1.477l6.401 1.129a.75.75 0 1 0 .26-1.477Zm.52-2.955-6.4-1.128a.75.75 0 1 0-.261 1.477l6.401 1.129a.75.75 0 1 0 .26-1.478Zm-2.433-3.475-3.447-.608a.75.75 0 1 0-.26 1.477l3.447.608a.75.75 0 1 0 .26-1.477Z"
          fill="#F4F4F6"
        />
        <path
          d="M60.278 59.38c2.153.482 3.82 2.206 4.375 4.344.07.138.208.276.347.276.208 0 .347-.138.347-.276.486-2.138 2.222-3.793 4.375-4.345.14-.069.278-.207.278-.345 0-.206-.139-.344-.278-.344-2.153-.552-3.82-2.276-4.375-4.414-.07-.138-.208-.276-.347-.276-.208 0-.347.138-.347.276-.556 2.138-2.222 3.862-4.375 4.345-.14.069-.278.207-.278.345 0 .206.07.344.278.413ZM32.833 93.208c.084-.041.167-.125.167-.208 0-.125-.083-.208-.167-.208a3.644 3.644 0 0 1-2.625-2.625C30.167 90.083 30.083 90 30 90c-.125 0-.208.083-.208.167a3.644 3.644 0 0 1-2.625 2.625c-.084.041-.167.125-.167.208 0 .125.083.208.167.208 1.291.292 2.291 1.334 2.625 2.625.041.084.125.167.208.167.125 0 .208-.083.208-.167.334-1.291 1.334-2.333 2.625-2.625ZM62.833 28.208c.084-.041.167-.125.167-.208 0-.125-.083-.208-.167-.208a3.644 3.644 0 0 1-2.625-2.625C60.167 25.083 60.083 25 60 25c-.125 0-.208.083-.208.167a3.644 3.644 0 0 1-2.625 2.625c-.084.041-.167.125-.167.208 0 .125.083.208.167.208 1.291.292 2.291 1.334 2.625 2.625.041.084.125.167.208.167.125 0 .208-.083.208-.167.334-1.291 1.334-2.333 2.625-2.625ZM104.778 75.722a4.858 4.858 0 0 1-3.5-3.5c-.056-.11-.167-.222-.278-.222-.167 0-.278.111-.278.222a4.858 4.858 0 0 1-3.5 3.5c-.11.056-.222.167-.222.278 0 .167.111.278.222.278 1.722.389 3.056 1.778 3.5 3.5.056.11.167.222.278.222.167 0 .278-.111.278-.222.389-1.722 1.778-3.056 3.5-3.5.11-.056.222-.167.222-.278 0-.111-.111-.222-.222-.278Z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
export const TaxNoDataIcon = (props: NoDataIconsProps) => {
  return (
    <svg
      {...props}
      width="160"
      height="160"
      viewBox="0 0 160 160"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <circle id="a" cx="80" cy="80" r="80" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <use fill="#ECECED" xlinkHref="#a" />
          <ellipse
            fill="#D4D4D5"
            mask="url(#b)"
            cx="80"
            cy="150.4"
            rx="77.257"
            ry="16"
          />
        </g>
        <path
          d="m135.464 79.074-.125-.09c-.518-.326-1.222-.213-1.636.286l-.306.367-.242-.135c-2.049-1.11-4.175-.837-5.552.825-1.376 1.661-1.218 3.07.083 5.326l.533.883.646 1.034c.831 1.379.975 2.125.411 2.801-.699.839-1.808.822-2.915-.128l-.214-.195c-.674-.656-.956-1.366-.879-2.12l.033-.22c.278-.826-.083-1.705-.838-2.054l-.073-.034c-.711-.329-1.567 0-1.912.737l-.042.099-.04.117-.082.326c-.336 1.515.003 2.977.99 4.296l.239.299-.313.376c-.449.541-.4 1.338.109 1.78l.122.092c.505.334 1.2.227 1.617-.273l.293-.354.246.152c2.231 1.308 4.601 1.035 6.157-.826 1.57-1.88 1.365-3.45-.138-6.016l-.915-1.486c-.912-1.472-1.104-2.137-.675-2.653.523-.63 1.539-.558 2.469.22l.217.195c.475.46.732.963.79 1.531l.011.244c-.107.837.411 1.602 1.215 1.79l.115.026c.714.166 1.452-.29 1.647-1.02l.025-.113.017-.132.013-.348c.007-1.136-.336-2.18-1.022-3.127l-.264-.337.316-.376c.45-.542.392-1.332-.131-1.765Z"
          fill="#D4D4D5"
        />
        <path
          d="M94.279 137.118c1.239.042 4.755-.069 5.916-.332 6.607-1.496 10.83-7.662 11.084-15.192.268-7.939-4.71-14.014-10.908-15.123-.807-.144-3.932-.03-4.787 0-8.285.285-14.007 6.84-14.305 15.123-.299 8.285 4.716 15.24 13 15.524Z"
          fill="#98989A"
        />
        <path
          d="M93.738 137.515c8.284.284 15.242-6.662 15.54-15.515.3-8.853-6.174-15.8-14.458-15.515-8.285.284-15.243 7.23-15.541 15.515-.299 8.284 6.175 15.23 14.459 15.515Z"
          fill="#727275"
        />
        <path
          d="M70.863 88.493c.984 0 1.753 1.053 1.828 2.042l.006.15.01 21.134c0 1.01.753 1.84 1.708 1.919l.145.006 21.384.032c.042 0 .085.002.127.005 1.02.077 1.782 1.023 1.703 2.114-.05.692-.108 1.259-.172 1.7-1.828 12.719-12.083 22.152-24.383 21.735-13.502-.458-24.559-11.932-24.696-25.628-.11-10.987 6.286-21.894 16.468-24.526 1.138-.294 2.256-.315 3.5-.48.563-.075 1.687-.203 2.372-.203Zm9.084-3.512a1.8 1.8 0 0 1 .258.01c.301.033.595.07.88.112 4.833.708 9.178 3.397 12.607 6.648 3.936 3.776 6.158 8.463 7.027 14.362.024.165.048.33.07.493.146 1.083-.538 1.968-1.572 2.272-.72.211-1.91.57-3.15.888-2.698-.172-4.802-.267-6.31-.288-2.057-.028-5.588-.028-10.593 0-.614.002-1.112-.514-1.112-1.154l-2.5-22.25c1.611-.46 3.128-.787 3.944-1.022.145-.041.295-.066.45-.071Z"
          fill="#98989A"
        />
        <path
          d="M67.326 88.819c.984-.03 1.79.726 1.865 1.715l.006.15.01 21.634c0 1.011.753 1.84 1.708 1.919l.145.006 20.884.032c.042 0 .085.002.127.005 1.02.077 1.782 1.024 1.703 2.115-.05.691-.108 1.258-.172 1.7-1.828 12.718-12.083 22.15-24.383 21.734-13.502-.458-24.559-11.932-24.696-25.627-.122-12.253 8.698-22.673 20.42-25.053a24.2 24.2 0 0 1 2.247-.321c.045-.005.09-.008.136-.01Zm8.62-2.839a1.8 1.8 0 0 1 .259.01c.301.033.595.07.88.112 4.833.708 9.178 2.897 12.607 6.148 3.936 3.777 6.658 8.963 7.527 14.862.024.165.048.33.07.493.146 1.083-.558 2.1-1.572 2.272a1.744 1.744 0 0 1-.283.024l-20.27.076c-.614.003-1.112-.514-1.112-1.154l.021-20.85c.001-1.066.84-1.959 1.874-1.993Z"
          fill="#BCBCBC"
        />
        <path
          d="m59.163 107.179-.155.011c-.606.083-1.072.622-1.069 1.27l.002.48-.272.05c-2.282.468-3.737 2.043-3.723 4.201.014 2.158 1.04 3.135 3.487 4.027l.976.334 1.16.376c1.523.523 2.112 1.002 2.115 1.882.004 1.092-.857 1.792-2.316 1.776l-.289-.012c-.938-.07-1.61-.432-2.036-1.059l-.116-.189c-.318-.812-1.16-1.253-1.962-1.035l-.078.021c-.756.205-1.2 1.007-.991 1.793l.031.103.045.116.146.302c.717 1.377 1.917 2.279 3.521 2.654l.375.076.002.49c.004.702.553 1.281 1.227 1.292l.153-.007c.602-.069 1.065-.598 1.063-1.249l-.003-.46.287-.041c2.55-.432 4.19-2.165 4.185-4.59-.005-2.45-1.171-3.52-3.973-4.52l-1.656-.55c-1.645-.542-2.22-.928-2.223-1.6-.004-.818.82-1.416 2.033-1.418l.292.01c.66.048 1.18.268 1.59.666l.165.179c.456.71 1.344.963 2.08.59l.106-.053c.654-.332.925-1.157.606-1.84l-.053-.103-.073-.113-.213-.274c-.725-.875-1.66-1.454-2.793-1.739l-.419-.088v-.491c-.003-.705-.555-1.273-1.234-1.268Z"
          fill="#ECECED"
        />
        <path
          d="M36.928 54.804a4 4 0 0 1 5.464 1.464l4 6.928a4 4 0 0 1-1.464 5.464l-13.856 8a4 4 0 0 1-5.464-1.464l-4-6.928a4 4 0 0 1 1.464-5.464l13.856-8Zm.333 1.828-12.855 7.422a.75.75 0 0 0-.315.943c.21.495.278.909.223 1.247-.05.307-.244.622-.614.952a.75.75 0 0 0-.15.935l3.201 5.545a.75.75 0 0 0 .817.357c.459-.106.84-.107 1.149-.017.297.087.64.324 1.016.726a.75.75 0 0 0 .923.138l12.733-7.352a.75.75 0 0 0 .366-.767c-.093-.59-.104-1.073-.038-1.447.058-.33.265-.746.635-1.232a.75.75 0 0 0 .052-.829l-3.101-5.372a.75.75 0 0 0-.806-.359c-.449.096-.829.091-1.148-.005-.324-.097-.733-.357-1.208-.79a.75.75 0 0 0-.88-.095Zm.293 1.563.193.147c.402.293.791.498 1.171.612l.219.057c.295.066.604.091.926.077l.196-.016 2.606 4.515-.014.022c-.324.502-.53.981-.611 1.445l-.035.243c-.038.332-.04.694-.007 1.086l.01.095L30.393 73.3l-.115-.099a3.29 3.29 0 0 0-1.14-.623l-.218-.055a3.448 3.448 0 0 0-.93-.064l-.2.018-2.66-4.607.053-.061c.327-.409.534-.85.611-1.322l.027-.217a3.428 3.428 0 0 0-.103-1.135l-.026-.09 11.862-6.849Zm-5.179 4.722c-1.435.829-1.87 2.76-.973 4.315.897 1.555 2.788 2.143 4.223 1.315 1.435-.829 1.87-2.76.973-4.315-.897-1.554-2.788-2.143-4.223-1.315Z"
          fill="#D4D4D5"
        />
        <path
          d="M32.24 52.967c1.076-.973 3.077-1.217 4.044-.16.125.138.235.287.327.448l-13.856 8 9.484-8.288Z"
          fillOpacity=".4"
          fill="#98989A"
        />
        <path
          d="M84 48c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16Z"
          fill="#D4D4D5"
          fillRule="nonzero"
        />
        <path
          d="M89.912 24.3c.62.46.766 1.295.361 1.923l-.082.115-10.356 13.093a1.532 1.532 0 0 1-2.103.269 1.424 1.424 0 0 1-.36-1.923l.082-.115L87.81 24.569a1.532 1.532 0 0 1 2.103-.269ZM89 33.324c1.657 0 3 1.302 3 2.907 0 1.606-1.343 2.908-3 2.908s-3-1.302-3-2.908c0-1.605 1.343-2.907 3-2.907Zm-10-8.721c1.657 0 3 1.301 3 2.907 0 1.605-1.343 2.907-3 2.907s-3-1.302-3-2.907c0-1.606 1.343-2.907 3-2.907Z"
          fill="#F4F4F6"
        />
        <path
          d="M98.278 73.38c2.153.482 3.82 2.206 4.375 4.344.07.138.208.276.347.276.208 0 .347-.138.347-.276.486-2.138 2.222-3.793 4.375-4.345.14-.069.278-.207.278-.345 0-.206-.139-.344-.278-.344-2.153-.552-3.82-2.276-4.375-4.414-.07-.138-.208-.276-.347-.276-.208 0-.347.138-.347.276-.556 2.138-2.222 3.862-4.375 4.345-.14.069-.278.207-.278.345 0 .206.07.344.278.413ZM38.833 93.208c.084-.041.167-.125.167-.208 0-.125-.083-.208-.167-.208a3.644 3.644 0 0 1-2.625-2.625C36.167 90.083 36.083 90 36 90c-.125 0-.208.083-.208.167a3.644 3.644 0 0 1-2.625 2.625c-.084.041-.167.125-.167.208 0 .125.083.208.167.208 1.291.292 2.291 1.334 2.625 2.625.041.084.125.167.208.167.125 0 .208-.083.208-.167.334-1.291 1.334-2.333 2.625-2.625ZM124.833 44.208c.084-.041.167-.125.167-.208 0-.125-.083-.208-.167-.208a3.644 3.644 0 0 1-2.625-2.625c-.041-.084-.125-.167-.208-.167-.125 0-.208.083-.208.167a3.644 3.644 0 0 1-2.625 2.625c-.084.041-.167.125-.167.208 0 .125.083.208.167.208 1.291.292 2.291 1.334 2.625 2.625.041.084.125.167.208.167.125 0 .208-.083.208-.167.334-1.291 1.334-2.333 2.625-2.625ZM71.778 62.722a4.858 4.858 0 0 1-3.5-3.5C68.222 59.112 68.11 59 68 59c-.167 0-.278.111-.278.222a4.858 4.858 0 0 1-3.5 3.5c-.11.056-.222.167-.222.278 0 .167.111.278.222.278 1.722.389 3.056 1.778 3.5 3.5.056.11.167.222.278.222.167 0 .278-.111.278-.222.389-1.722 1.778-3.056 3.5-3.5.11-.056.222-.167.222-.278 0-.111-.111-.222-.222-.278Z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export const BillPayNoDataIcon = (props: NoDataIconsProps) => {
  return (
    <svg
      {...props}
      width="160"
      height="160"
      viewBox="0 0 160 160"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <circle id="a" cx="80" cy="80" r="80" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <use fill="#ECECED" xlinkHref="#a" />
          <ellipse
            fill="#D4D4D5"
            mask="url(#b)"
            cx="80"
            cy="150.4"
            rx="77.257"
            ry="16"
          />
        </g>
        <path
          d="m92.315 30.201-.154.017c-.602.104-1.05.659-1.024 1.307l.019.478-.27.06c-2.265.548-3.663 2.173-3.575 4.329.09 2.156 1.15 3.097 3.625 3.903l.988.3 1.172.335c1.54.469 2.146.927 2.18 1.807.042 1.09-.794 1.82-2.252 1.856l-.29-.002c-.94-.037-1.624-.376-2.072-.988l-.122-.184c-.347-.802-1.203-1.213-1.998-.967l-.076.024c-.75.231-1.165 1.049-.928 1.827l.035.101.049.114.156.297c.764 1.351 1.995 2.211 3.611 2.53l.377.063.02.489c.028.702.597 1.261 1.271 1.25l.153-.014c.599-.09 1.044-.635 1.019-1.285l-.02-.459.285-.052c2.534-.52 4.112-2.31 4.023-4.733-.09-2.448-1.293-3.477-4.128-4.379l-1.675-.492c-1.662-.483-2.25-.85-2.276-1.52-.033-.818.77-1.444 1.982-1.489h.291c.661.025 1.19.227 1.612.61l.172.173c.48.694 1.377.916 2.1.518l.103-.058c.642-.354.885-1.188.542-1.86l-.057-.1-.076-.11-.223-.268c-.755-.848-1.71-1.395-2.852-1.64l-.422-.073-.017-.491c-.027-.705-.6-1.252-1.278-1.224Z"
          fill="#D4D4D5"
        />
        <path
          d="M101.01 141.718c1.239.042 4.755-.069 5.916-.332 6.607-1.496 10.83-7.662 11.084-15.192.268-7.939-4.71-14.014-10.908-15.123-.807-.144-3.932-.03-4.787 0-8.284.285-14.006 6.84-14.305 15.123-.299 8.285 4.716 15.24 13 15.524Z"
          fill="#98989A"
        />
        <path
          d="M100.47 142.115c8.283.284 15.241-6.662 15.54-15.515.299-8.853-6.175-15.8-14.46-15.515-8.284.284-15.241 7.23-15.54 15.515-.299 8.284 6.175 15.23 14.46 15.515Z"
          fill="#727275"
        />
        <path
          d="M100.728 116.52c-.55.017-1.02.475-1.084 1.082l-.048.448-.248.02c-2.085.215-3.548 1.553-3.762 3.584-.213 2.03.605 3.042 2.705 4.097l.841.4 1.001.454c1.314.621 1.794 1.12 1.708 1.94-.107 1.017-.951 1.597-2.26 1.46l-.259-.036c-.835-.144-1.402-.54-1.72-1.164l-.085-.187c-.201-.789-.91-1.275-1.654-1.14l-.072.013c-.7.127-1.182.844-1.077 1.6l.017.1.029.112.1.297c.5 1.353 1.485 2.299 2.89 2.78l.33.102-.048.458c-.07.658.366 1.243.972 1.307l.139.005c.548-.017 1.02-.474 1.083-1.082l.044-.428.263-.016c2.337-.195 3.985-1.669 4.221-3.92.24-2.273-.699-3.371-3.108-4.556l-1.427-.666c-1.417-.658-1.891-1.073-1.825-1.702.08-.767.88-1.248 1.963-1.135l.26.038c.585.107 1.027.362 1.353.772l.13.182c.336.706 1.106 1.024 1.801.746l.1-.04c.619-.247.943-.987.726-1.652l-.037-.1-.053-.112-.164-.276a4.643 4.643 0 0 0-2.32-1.89l-.364-.124.05-.458c.069-.658-.367-1.243-.973-1.307l-.138-.005Z"
          fill-opacity=".75"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M94 96.778V135.895c.106 3.567-1.668 6.506-5.322 8.818l-4.004-2.021-2.793-1.506-10.107 4.334-3.954-2.05-4.795-4.808-7.358 1.534-3.99-2.046c1.549-2.588 2.323-4.412 2.323-5.47V97.55a7 7 0 0 1 6.537-6.984l23.238-1.54 4.552.878A7 7 0 0 1 94 96.778Z"
          fill="#98989A"
        />
        <path
          d="M90 96v37.895c.106 3.567-1.668 6.506-5.322 8.818l-6.687-3.632-10.217 4.439-8.75-6.858-7.357 1.534c-1.111-2.434-1.667-4.94-1.667-7.517V96a7 7 0 0 1 7-7h26a7 7 0 0 1 7 7Z"
          fill="#BCBCBC"
        />
        <path
          d="M60 109.593c-1.105-.037-2 .913-2 2.122 0 1.209.895 2.23 2 2.282l18.904.794c1.104.052 2-.946 2-2.228 0-1.282-.896-2.351-2-2.388L60 109.593ZM60 100c-1.105 0-2 .98-2 2.189 0 1.208.895 2.2 2 2.215l18.904.263c1.104.014 2-1.013 2-2.295s-.896-2.322-2-2.322L60 100Zm0 19.611c-1.105 0-2 .98-2 2.189 0 1.208.895 2.2 2 2.215l18.904 1.045c1.104.015 2-1.013 2-2.295s-.896-2.321-2-2.321L60 119.61Z"
          fill="#ECECED"
        />
        <path
          d="M40.32 54.464a4 4 0 0 1 5.465 1.464l4 6.928a4 4 0 0 1-1.464 5.465l-13.857 8A4 4 0 0 1 29 74.856l-4-6.928a4 4 0 0 1 1.464-5.464l13.857-8Zm.333 1.829-12.855 7.422a.75.75 0 0 0-.315.942c.21.495.278.91.223 1.248-.05.306-.244.622-.614.951a.75.75 0 0 0-.15.935l3.201 5.546a.75.75 0 0 0 .817.356c.46-.105.84-.107 1.15-.016.296.086.639.323 1.015.726a.75.75 0 0 0 .923.137l12.734-7.352a.75.75 0 0 0 .365-.767c-.093-.59-.103-1.073-.038-1.447.059-.33.266-.746.635-1.232a.75.75 0 0 0 .053-.829l-3.102-5.372a.75.75 0 0 0-.806-.358c-.449.096-.828.09-1.147-.005-.325-.098-.734-.358-1.209-.79a.75.75 0 0 0-.88-.095Zm.293 1.563.193.146c.403.293.791.498 1.171.612l.219.058c.295.065.604.09.926.076l.196-.015 2.607 4.514-.014.023c-.324.5-.53.98-.612 1.444l-.035.244c-.038.331-.04.693-.006 1.086l.009.094-11.814 6.821-.116-.098a3.29 3.29 0 0 0-1.14-.624l-.218-.055a3.448 3.448 0 0 0-.93-.063l-.2.017-2.66-4.607.053-.06c.327-.41.534-.851.611-1.323l.027-.216a3.428 3.428 0 0 0-.103-1.136l-.026-.09 11.862-6.848Zm-5.179 4.722c-1.435.828-1.87 2.76-.973 4.314.898 1.555 2.788 2.143 4.223 1.315 1.435-.829 1.87-2.76.973-4.315-.897-1.554-2.788-2.143-4.223-1.314Z"
          fill="#D4D4D5"
        />
        <path
          d="M35.632 52.627c1.076-.972 3.078-1.217 4.044-.159.125.137.235.287.328.447l-13.857 8 9.485-8.288Z"
          fillOpacity=".4"
          fill="#98989A"
        />
        <g fill="#D4D4D5">
          <path d="M137.78 70.692c.447.325.886 1.031.98 1.576l1.435 8.328c.094.545-.098 1.357-.417 1.796l-6.298 8.669a2.993 2.993 0 0 1-4.187.659l-10.517-7.641a3 3 0 0 1-.663-4.192l9.403-12.942a2.999 2.999 0 0 1 4.186-.669l6.077 4.416Zm-6.122 14.248-7.165-5.206a1 1 0 0 0-1.397.221l-.037.05a1 1 0 0 0 .221 1.398l7.166 5.206a1 1 0 0 0 1.397-.221l.036-.051a1 1 0 0 0-.22-1.397Zm2.425-3.337-7.166-5.206a1 1 0 0 0-1.397.221l-.036.05a1 1 0 0 0 .22 1.398l7.166 5.206a1 1 0 0 0 1.397-.222l.037-.05a1 1 0 0 0-.221-1.397Zm-.87-5.73-3.871-2.813a1 1 0 0 0-1.397.221l-.037.05a1 1 0 0 0 .222 1.397L132 77.541a1 1 0 0 0 1.397-.22l.037-.051a1 1 0 0 0-.221-1.397Z" />
        </g>
        <path
          d="M60.278 59.38c2.153.482 3.82 2.206 4.375 4.344.07.138.208.276.347.276.208 0 .347-.138.347-.276.486-2.138 2.222-3.793 4.375-4.345.14-.069.278-.207.278-.345 0-.206-.139-.344-.278-.344-2.153-.552-3.82-2.276-4.375-4.414-.07-.138-.208-.276-.347-.276-.208 0-.347.138-.347.276-.556 2.138-2.222 3.862-4.375 4.345-.14.069-.278.207-.278.345 0 .206.07.344.278.413ZM32.833 93.208c.084-.041.167-.125.167-.208 0-.125-.083-.208-.167-.208a3.644 3.644 0 0 1-2.625-2.625C30.167 90.083 30.083 90 30 90c-.125 0-.208.083-.208.167a3.644 3.644 0 0 1-2.625 2.625c-.084.041-.167.125-.167.208 0 .125.083.208.167.208 1.291.292 2.291 1.334 2.625 2.625.041.084.125.167.208.167.125 0 .208-.083.208-.167.334-1.291 1.334-2.333 2.625-2.625ZM62.833 28.208c.084-.041.167-.125.167-.208 0-.125-.083-.208-.167-.208a3.644 3.644 0 0 1-2.625-2.625C60.167 25.083 60.083 25 60 25c-.125 0-.208.083-.208.167a3.644 3.644 0 0 1-2.625 2.625c-.084.041-.167.125-.167.208 0 .125.083.208.167.208 1.291.292 2.291 1.334 2.625 2.625.041.084.125.167.208.167.125 0 .208-.083.208-.167.334-1.291 1.334-2.333 2.625-2.625ZM104.778 75.722a4.858 4.858 0 0 1-3.5-3.5c-.056-.11-.167-.222-.278-.222-.167 0-.278.111-.278.222a4.858 4.858 0 0 1-3.5 3.5c-.11.056-.222.167-.222.278 0 .167.111.278.222.278 1.722.389 3.056 1.778 3.5 3.5.056.11.167.222.278.222.167 0 .278-.111.278-.222.389-1.722 1.778-3.056 3.5-3.5.11-.056.222-.167.222-.278 0-.111-.111-.222-.222-.278Z"
          fill="#FFF"
          fill-Rule="nonzero"
        />
      </g>
    </svg>
  );
};
