import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import TooltipLg from '@material-ui/core/Tooltip';
import clsx from 'clsx';

import styles from './toggle-switch.module.scss';

export enum ToggleSwitchAlign {
  SPACE_BETWEEN,
  DEFAULT,
}

interface ToggleSwitchProps {
  label?: React.ReactElement;
  className?: string;
  value?: any;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  inverse?: boolean;
  disabled?: boolean;
  disabledInfo?: string;
  align?: ToggleSwitchAlign;
  color?: 'blue' | 'green' | 'primary';
}

export const ToggleSwitch = ({
  label,
  className,
  value,
  checked,
  onChange,
  name,
  disabled,
  disabledInfo = '',
  align = ToggleSwitchAlign.SPACE_BETWEEN,
  color = 'green',
  inverse,
}: ToggleSwitchProps) => {
  const classes = clsx(
    styles.root,
    className,
    inverse && styles.inverse,
    disabled && styles.disabled,
    align === ToggleSwitchAlign.SPACE_BETWEEN && styles.spaceBetween,
    color === 'blue' && styles.blue,
    color === 'primary' && styles.primary
  );
  return (
    <div className={classes}>
      {label}
      <FormControlLabel
        control={
          <TooltipLg arrow title={disabled ? disabledInfo : ''} placement="top">
            <span className={styles.switch}>
              <input
                type={'checkbox'}
                className={styles.checkboxInput}
                checked={checked}
                onChange={onChange}
                value={value}
                name={name}
                {...(disabled && { disabled: disabled })}
              />
              <span className={`${styles.slider} ${styles.round}`} />
            </span>
          </TooltipLg>
        }
        label={''}
      />
    </div>
  );
};
