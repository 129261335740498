import React from 'react';

import { userMsgs } from '@puretax/shared';

import styles from './error-boundary.module.scss';

interface Props {
  children: React.ReactNode;
}
interface State {
  hasError: boolean;
}
export class ErrorBoundary extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(error: Error) {
    console.warn(error);
    return { hasError: true };
  }

  public render(): React.ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;
    const { appGlobalErrorTitle, appGlobalErrorDesc } = userMsgs;
    if (hasError) {
      return (
        <div className={styles.alert}>
          <h2>Something is broken on this page</h2>
          <h4>{`${appGlobalErrorTitle} ${appGlobalErrorDesc}`}</h4>
          <a href={'/to-do'} className={styles.link}>
            Return to Tasks
          </a>
        </div>
      );
    }
    return children;
  }
}
