import React, { useEffect, useState } from 'react';

import { ClickAwayListener, Grid, Paper, Popper } from '@material-ui/core';
import clsx from 'clsx';

import { ButtonProps } from '..';
import styles from './mui-popper.module.scss';

export interface MuiPopperProps {
  id: string;
  open?: boolean;
  rootPaperClassName?: string;
  paperClassName?: string;
  children?: React.ReactElement;
  anchorOrigin?: 'left' | 'right' | 'center' | 'topLeft';
  onClose?: () => void;
  onOpen?: () => void;
  renderButton?: (props?: PopperButtonProps) => React.ReactElement;
}

type PopperButtonProps = ButtonProps;

const MuiPopperComponent = (props: MuiPopperProps) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    props.onOpen?.();
    event.stopPropagation();
  };
  useEffect(() => {
    if (props.open && props.id) {
      setTimeout(() => {
        setAnchorEl(document.querySelector('#' + props.id + 'btn'));
      }, 50);
    }
  }, [props.id, props.open]);

  useEffect(() => {
    if (!props.open) {
      setAnchorEl(null);
    }
  }, [props.open]);

  const handleClose = () => {
    setAnchorEl(null);
    props.onClose?.();
  };

  const id = props.open ? props.id : undefined;

  return (
    <>
      {props.renderButton({ onClick: handleClick, id: props.id })}
      <ClickAwayListener onClickAway={handleClose}>
        <Popper id={id} open={props.open} anchorEl={anchorEl}>
          <Paper className={styles.paperClassName}>
            <div className={clsx(styles.paper, props.paperClassName)}>
              {props.children}
            </div>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </>
  );
};

export default MuiPopperComponent;
